import { createRouter, createWebHistory } from "vue-router";

import LoginForm from "../components/LoginForm.vue";
import ForgotPassword from "../components/ForgotPassword.vue";
import MainPage from "../components/MainPage.vue";
import AccessCode from "../components/AccessCode.vue";
import AddProfile from "../views/Profile/AddProfile.vue";
import Profile from "../components/Profile";
import Offer from "../components/Offer";
import ProfileIndex from "../views/Profile/Index.vue";

const routes = [
  {
    path: "/logowanie",
    name: "LoginForm",
    component: LoginForm,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/nie-pamietam-hasla",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/",
    name: "MainPage",
    component: MainPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dodaj-profil",
    name: "AddProfile",
    component: AddProfile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profil/:id",
    name: "Profile",
    component: Profile,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/oferta",
    name: "Offer",
    component: Offer,
  },
  {
    path: "/kod-dostepu",
    name: "AccessCode",
    component: AccessCode,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/:id",
    name: "ProfileIndex",
    component: ProfileIndex,
    redirect: { name: "GeneralInformation" },
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        path: "informacje-ogolne",
        name: "GeneralInformation",
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (profile-general-information.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "profile-general-information" */ "../views/Profile/GeneralInformation.vue"
          ),
      },
      {
        path: "zdjecia",
        name: "Photos",
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (profile-photos.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "profile-photos" */ "../views/Profile/Photos.vue"
          ),
      },
      {
        path: "filmy",
        name: "Videos",
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (profile-videos.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "profile-videos" */ "../views/Profile/Videos.vue"
          ),
      },
      {
        path: "edycja",
        name: "EditProfile",
        meta: {
          requiresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (profile-edit.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "profile-edit" */ "../views/Profile/EditProfile.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const redirectUnathorized = (userData, to) => {
  if (!userData && to.meta.requiresAuth) {
    router.push({
      name: "LoginForm",
    });
  } else if (userData && !to.meta.requiresAuth) {
    router.push({
      name: "MainPage",
    });
  }
};

router.beforeEach(async (to, from, next) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  redirectUnathorized(userData, to);
  next();
});

export default router;
