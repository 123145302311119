<template>
  <div class="flex align-items-center justify-content-center">
    <Card class="card login-card mt-8 mb-8">
      <template #title>
        <div class="line ml-4 mr-4">
          <div class="pt-4 pb-3">Nie pamiętasz hasła?</div>
        </div>
      </template>
      <template #content>
        <h5 class="email-label ml-6">E-mail</h5>
        <InputText
          class="text-input pl-4 ml-4 mr-4"
          type="email"
          aria-describedby="email-help"
          v-model="emailValue"
        />
        <p class="input-error ml-6" v-if="emailError">{{ emailError }}</p>
        <div class="btn-container">
          <Button
            class="p-button-rounded submit-btn mt-3 ml-4 mr-4 pl-4"
            type="submit"
            :loading="loading"
            @click="sendEmail(e)"
          >
            Wyślij nowe hasło
          </Button>
        </div>
        <Toast position="bottom-center" />
      </template>
    </Card>
  </div>
</template>
<script>
import errorCodes from "../const/errorCodes";
import { isEmail } from "validator";
import AdminAuthService from "../services/AdminAuthService.js";

export default {
  name: "ForgotPassword",

  data() {
    return {
      emailValue: "",
      loading: false,
      error: "",
      emailError: "",
    };
  },
  methods: {
    async sendEmail() {
      try {
        this.loading = true;

        this.emailError = "";
        this.error = "";

        if (!this.emailValue) {
          this.emailError = "Email jest wymagany";
          return;
        }
        if (!isEmail(this.emailValue)) {
          this.emailError = "Podano niepoprawny email";
          return;
        }

        await AdminAuthService.resetPassword(this.emailValue);

        this.$router.push({
          path: "/",
        });
      } catch (err) {
        this.error = errorCodes[err.errorCode];
        this.$toast.add({
          severity: "error",
          summary: "Wystąpił problem!",
          detail: this.error,
          life: 5000,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main";

.email-label {
  margin-top: -0.5rem;
}
</style>
