class QueryHandler {
  static buildQuery(url, params) {
    if (!params || Object.keys(params).length === 0) return url;
    const queryString = `${url}?`;
    const queries = [];

    if (params.stringQuery.length > 0) {
      params.stringQuery.map((query) => {
        queries.push(
          `${query.name}[contain]=${query.contain}&${query.name}[val]=${query.val}`
        );
      });
    }

    if (params.enumQuery.length > 0) {
      params.enumQuery.map((query) => {
        queries.push(`${query.name}=${query.val}`);
      });
    }

    if (params.rangeQuery.length > 0) {
      params.rangeQuery.map((query) => {
        queries.push(
          `${query.name}[gte]=${query.gte}&${query.name}[lte]=${query.lte}`
        );
      });
    }

    if (params.limit) {
      queries.push(`limit=${params["limit"]}`);
    }
    if (params.page) {
      queries.push(`page=${params["page"]}`);
    }

    const finalQuery = queryString + queries.join("&");
    return finalQuery;
  }
}

export default QueryHandler;
