import config from "../../configuration.json";
import axios from "axios";

axios.defaults.withCredentials = true;

const url = `${config.VUE_APP_API_URL}/admin`;

class AdminAuthService {
  static login(email, password) {
    return new Promise((resolve, reject) => {
      const requestBody = { email, password };
      axios
        .post(`${url}/login`, requestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }
  static logout() {
    return new Promise((resolve) => {
      axios.post(`${url}/logout`).then((res) => {
        resolve(res.data);
      });
    });
  }
  static resetPassword(email) {
    const requestBody = { email };
    return new Promise((resolve, reject) => {
      axios
        .post(`${url}/resetPassword`, requestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }
}

export default AdminAuthService;
