<template>
  <div class="flex align-items-center justify-content-center">
    <Card class="card login-card mt-8 mb-8">
      <template #title>
        <div class="line ml-4 mr-4">
          <div class="pt-4 pb-3">Zaloguj się</div>
        </div>
      </template>
      <template #content>
        <form @submit.prevent="logIn">
          <h5 class="email-label ml-6">E-mail</h5>
          <InputText
            class="text-input pl-4 ml-4 mr-4"
            type="email"
            aria-describedby="email-help"
            v-model="emailValue"
          />
          <p class="input-error ml-6" v-if="emailError">{{ emailError }}</p>
          <h5 class="ml-6">Hasło</h5>
          <InputText
            class="text-input pl-4 ml-4 mr-4"
            type="password"
            aria-describedby="password-help"
            v-model="passwordValue"
          />
          <p class="input-error ml-6" v-if="passwordError">
            {{ passwordError }}
          </p>
          <div class="mt-3 ml-6">
            <router-link
              :to="{ name: 'ForgotPassword' }"
              class="password-forgot-link"
              >Nie pamiętasz hasła</router-link
            >
          </div>
          <div class="btn-container">
            <Button
              class="p-button-rounded submit-btn mt-3 ml-4 mr-4 pl-4"
              type="submit"
              :loading="loading"
            >
              Zaloguj się
            </Button>
          </div>
          <Toast position="bottom-center" />
        </form>
      </template>
    </Card>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import errorCodes from "../const/errorCodes";
const { isEmail } = require("validator");

export default {
  name: "LoginForm",

  data() {
    return {
      emailValue: "",
      passwordValue: "",
      loading: false,
      error: "",
      emailError: "",
      passwordError: "",
    };
  },
  methods: {
    ...mapActions("admins", { storeLogin: "login" }),
    async logIn(e) {
      this.emailError = "";
      this.passwordError = "";
      if (!this.emailValue) {
        this.emailError = "Email jest wymagany";
        return;
      }
      if (!this.passwordValue) {
        this.passwordError = "Hasło jest wymagane";
        return;
      }
      if (!isEmail(this.emailValue)) {
        this.emailError = "Podano niepoprawny email";
        return;
      }

      e.preventDefault();
      this.loading = true;
      this.error = "";
      await this.storeLogin([this.emailValue, this.passwordValue])
        .then(() => {
          this.$router.push({
            name: "MainPage",
          });
        })
        .catch((err) => {
          this.error = errorCodes[err.errorCode];
          this.$toast.add({
            severity: "error",
            summary: "Wystąpił problem!",
            detail: this.error,
            life: 5000,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main";

.email-label {
  margin-top: -0.5rem;
}
.password-forgot-link {
  color: $quinary;
}
</style>