<template>
  <div id="app-header" class="flex align-items-center justify-content-center">
    <router-link :to="{ name: 'MainPage' }">
      <img
        v-show="!shouldLogoBeHidden"
        id="logo-img"
        src="../assets/logo.svg"
        alt="menu"
      />
    </router-link>
    <Button
      v-if="loggedIn"
      class="logout-btn flex align-items-center justify-content-center"
      type="submit"
      @click="logout"
    >
      <i id="log-out-icon" class="pi pi-sign-out"></i>
    </Button>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    ...mapActions("admins", { storeLogout: "logout" }),
    ...mapActions("actors", ["clearParams"]),
    async logout() {
      await this.clearParams();
      await this.storeLogout();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    ...mapGetters("admins", ["loggedIn"]),
    shouldLogoBeHidden() {
      return (
        this.$route.name === "GeneralInformation" && this.windowWidth < 1025
      );
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main";
#app-header {
  background-color: $tertiary;
  height: 5.25rem;
  position: relative;
  border-bottom: 1vh solid $primary;
  border-top: 1vh solid $primary;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 64em) {
    height: 10vh;
  }
}
#logo-img {
  height: 2.75rem;
}
#log-out-icon {
  color: $quaternary;
}
.logout-btn {
  background-color: $tertiary;
  border: 0;
  box-shadow: -1px 1px 5px $quaternary;
  z-index: 1;
  position: absolute;
  right: 2rem;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}
</style>
