import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Toolbar from "primevue/toolbar";
import Card from "primevue/card";
import Message from "primevue/message";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import MultiSelect from "primevue/multiselect";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Slider from "primevue/slider";
import ProgressSpinner from "primevue/progressspinner";
import InputNumber from "primevue/inputnumber";
import InputMask from "primevue/inputmask";
import Chips from "primevue/chips";
import Toast from "primevue/toast";
import ProgressBar from "primevue/progressbar";
import Dialog from "primevue/dialog";
import Image from "primevue/image";
import ConfirmPopup from "primevue/confirmpopup";
import Galleria from "primevue/galleria";
import FileUpload from "primevue/fileupload";
import Tooltip from "primevue/tooltip";
import BaseModal from "./components/base/BaseModal.vue";

export const registerGlobalComponents = (app) => {
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("InputNumber", InputNumber);
  app.component("InputMask", InputMask);
  app.component("Chips", Chips);
  app.component("Toast", Toast);
  app.component("ProgressBar", ProgressBar);
  app.component("Image", Image);
  app.component("Galleria", Galleria);
  app.component("Dialog", Dialog);
  app.component("Button", Button);
  app.component("InputText", InputText);
  app.component("Toolbar", Toolbar);
  app.component("Card", Card);
  app.component("Message", Message);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("ColumnGroup", ColumnGroup);
  app.component("MultiSelect", MultiSelect);
  app.component("Calendar", Calendar);
  app.component("Dropdown", Dropdown);
  app.component("Slider", Slider);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("FileUpload", FileUpload);
  app.component("BaseModal", BaseModal);

  app.directive("tooltip", Tooltip);
};
