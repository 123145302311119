export const artist_category_enum = [
  "statysta",
  "epizodysta",
  "aktor zawodowy",
  "aktor",
  "hostessa/host",
  "kaskader",
  "tancerz",
  "model",
  "obcokrajowiec",
  "stand-uper",
  "komik",
  "lektor",
  "senior",
  "muzyk",
  "zawodnik sztuk walki",
  "dziecko",
  "nastolatek",
];

export const gender_enum = ["K.", "M."];

export const clothing_size_enum = [
  "XXS",
  "XXS/XS",
  "XS",
  "XS/S",
  "S",
  "S/M",
  "M",
  "M/L",
  "L",
  "L/XL",
  "XL",
  "XL/XXL",
  "XXL",
  "XXL/XXXL",
  "XXXL",
];

export const hair_length_enum = ["krótkie", "średnie", "długie", "brak"];

export const hair_color_enum = [
  "blond",
  "ciemny blond",
  "jasny blond",
  "siwe",
  "szpakowate",
  "brąz",
  "jasny brąz",
  "ciemny brąz",
  "czarne",
  "rude",
  "kolorowe",
  "łysy/brak włosów",
];

export const eye_color_enum = [
  "niebieskie",
  "brązowe",
  "czarne",
  "zielone",
  "szare",
  "heterochromia",
];

export const skin_color_enum = [
  null,
  "inna",
  "biała",
  "brązowa",
  "czarna",
  "żółta",
];

export const driving_licence_enum = [
  "A1",
  "A",
  "B1",
  "B",
  "C1",
  "C",
  "D1",
  "D",
  "BE",
  "C1E",
  "CE",
  "D1E",
  "CE",
  "T",
];

export const province_enum = [
  "dolnośląskie",
  "kujawsko-pomorskie",
  "lubelskie",
  "lubuskie",
  "łódzkie",
  "małopolskie",
  "mazowieckie",
  "opolskie",
  "podkarpackie",
  "podlaskie",
  "pomorskie",
  "śląskie",
  "świętokrzyskie",
  "warmińsko-mazurskie",
  "wielkopolskie",
  "zachodnio-pomorskie",
];

export const yes_no_enum = ["TAK", "NIE"];
