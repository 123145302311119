import router from "../../router/index";

export default {
  SET_USER_DATA(state, userData) {
    state.userData = { ...userData };
    localStorage.setItem("userData", JSON.stringify(userData));
  },
  CLEAR_USER_DATA(state) {
    localStorage.removeItem("userData");
    state.userData = null;
    router.push({
      name: "LoginForm",
    });
  },
  SET_OFFERS(state, offers) {
    state.offers = [...offers];
  },
};
