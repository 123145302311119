import config from "../../configuration.json";
import axios from "axios";
axios.defaults.withCredentials = true;

const url = `${config.VUE_APP_API_URL}/offer`;

class OfferService {
  static checkAccessCode(accessCode) {
    return new Promise((resolve, reject) => {
      const requestBody = { accessCode };
      axios
        .post(`${url}/`, requestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  }
  static generateOffer(actors, offerName) {
    const requestBody = { actors, name: offerName };
    return new Promise((resolve, reject) => {
      axios
        .post(`${url}/generateOffer`, requestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getAllOffers() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static addActorsToOffer(offerId, actors) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url}/${offerId}/actors`, { actors })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }
}
export default OfferService;
