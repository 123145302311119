export default {
  SET_ACTOR(state, actor) {
    state.actor = { ...actor };
  },
  CLEAR_PARAMS(state) {
    state.params = { ...state.initialParams };
  },
  SET_PARAMS(state, params) {
    state.params = { ...params };
  },
  SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_SELECTED_ACTORS(state, selectedActors) {
    state.selectedActors = [...selectedActors];
  },
  CLEAR_SELECTED_ACTORS(state) {
    state.selectedActors = [];
  },
};
