export default {
  loggedIn(state) {
    return !!state.userData;
  },
  userData(state) {
    return state.userData;
  },
  offers(state) {
    return state.offers;
  },
};
