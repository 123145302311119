<template>
  <div class="modal px-4 pb-4 pt-2">
    <header>
      <slot name="header">
        <!-- h4 -> tytuł -->
      </slot>
    </header>
    <div class="mb-4">
      <slot name="main"></slot>
    </div>
    <footer>
      <Button
        type="button"
        @click="onModalClose()"
        class="mr-3 action-btn white"
        ><slot name="cancel-button"></slot
      ></Button>

      <Button
        v-if="step > 1"
        type="button"
        @click="onModalPrevious()"
        class="mr-3 action-btn"
        ><slot name="previous-button"></slot
      ></Button>
      <Button
        v-if="step > 1"
        type="button"
        @click="onModalSubmit()"
        class="mr-3 action-btn"
        ><slot name="confirm-button"></slot
      ></Button>
      <Button
        v-if="step < maxSteps"
        type="button"
        @click="onModalNext()"
        class="mr-3 action-btn"
        ><slot name="next-button"></slot
      ></Button>
    </footer>
  </div>
</template>
<script>
export default {
  name: "BaseModal",
  emits: ["close-modal", "confirm-modal", "next-modal", "previous-modal"],
  props: {
    step: {
      type: Number,
      default: 1,
    },
    maxSteps: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    onModalSubmit() {
      this.$emit("confirm-modal");
    },
    onModalClose() {
      this.$emit("close-modal");
    },
    onModalNext() {
      this.$emit("next-modal");
    },
    onModalPrevious() {
      this.$emit("previous-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables";
@import "../../styles/main_page";
@import "../../styles/main";
.modal {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>
