<template>
  <Card class="main-card mt-5 px-2 md:px-4">
    <template #content>
      <div class="line">
        <div><h2 class="mt-0">Informacje ogólne</h2></div>
      </div>
      <div class="grid">
        <div class="col-12 md:col-6">
          <p>
            <span class="bold-text"> ID: </span>
            {{ actor.identifierNumber }}
          </p>
          <p>
            <span class="bold-text">Płeć: </span><span>{{ actor.gender }}</span>
          </p>
          <p>
            <span class="bold-text">Numer kontaktowy: </span
            ><span>{{ actor.phone_number }}</span>
          </p>
          <p>
            <span class="bold-text">Numer do osoby bliskiej: </span
            ><span>{{ actor.friend_phone_number }}</span>
          </p>
          <p>
            <span class="bold-text">Wiek: </span
            ><span>{{ getAge(actor.birthday) }}</span>
          </p>
          <p class="mb-2">
            <span class="bold-text">Data urodzenia: </span
            ><span>{{
              actor.birthday ? formattedDate(actor.birthday) : ""
            }}</span>
          </p>
        </div>
        <div class="col-12 md:col-6 py-0 md:py-2">
          <p class="my-0 md:my-3">
            <span class="bold-text">Województwo: </span
            ><span>{{ actor.province }}</span>
          </p>
          <p>
            <span class="bold-text">Miejsce urodzenia: </span
            ><span>{{ actor.place_of_birth }}</span>
          </p>
          <p>
            <span class="bold-text">Miejsce zamieszkania: </span
            ><span>{{ actor.place_of_residence }}</span>
          </p>
          <p>
            <span class="bold-text">Miejsce zameldowania: </span
            ><span>{{ actor.place_of_registered_residence }}</span>
          </p>
          <p>
            <span class="bold-text">Email: </span><span>{{ actor.mail }}</span>
          </p>
        </div>
      </div>
      <div class="line" />
      <div class="grid">
        <div class="col-12 md:col-6">
          <h2>Kamera</h2>
          <p class="my-0 md:my-3">
            {{ actor.camera_experience }}
          </p>
        </div>
        <div class="col-12 md:col-6 py-0 md:py-2">
          <h2>Teatr</h2>
          <p>
            {{ actor.theatrical_experience }}
          </p>
        </div>
      </div>
      <div class="line" />
      <div class="grid">
        <div class="col-12 md:col-6">
          <h2>Aparycja</h2>
          <p>
            <span class="bold-text">Długość włosów: </span
            ><span>{{ actor.hair_length }}</span>
          </p>
          <p>
            <span class="bold-text">Kolor włosów: </span
            ><span v-for="(type, i) in actor.hair_color" :key="i">{{
              i === actor.hair_color.length - 1 ? type : type + ", "
            }}</span>
          </p>
          <p>
            <span class="bold-text">Kolor oczu: </span
            ><span v-for="(type, i) in actor.eye_color" :key="i">{{
              i === actor.eye_color.length - 1 ? type : type + ", "
            }}</span>
          </p>
          <p>
            <span class="bold-text">Kolor skóry: </span
            ><span>{{ actor.skin_color }}</span>
          </p>
          <p>
            <span class="bold-text">Znaki szczególne: </span
            ><span
              v-for="(type, i) in actor.physical_characteristics"
              :key="i"
              >{{
                i === actor.physical_characteristics.length - 1
                  ? type
                  : type + ", "
              }}</span
            >
          </p>
          <p class="mb-2">
            <span class="bold-text">Wzrost: </span
            ><span>{{ actor.height }}</span>
          </p>
        </div>
        <div class="col-12 md:col-6 py-0 md:py-2">
          <p class="my-0 md:my-3">
            <span class="bold-text">Waga: </span><span>{{ actor.weight }}</span>
          </p>
          <p>
            <span class="bold-text">Klatka: </span
            ><span>{{ actor.chest }}</span>
          </p>
          <p>
            <span class="bold-text">Pas: </span><span>{{ actor.waist }}</span>
          </p>
          <p>
            <span class="bold-text">Biodra: </span><span>{{ actor.hips }}</span>
          </p>
          <p>
            <span class="bold-text">Kołnierz: </span
            ><span>{{ actor.collar }}</span>
          </p>
          <p>
            <span class="bold-text">But: </span
            ><span>{{ actor.shoe_number }}</span>
          </p>
          <p>
            <span class="bold-text">Rozmiar odzieży: </span
            ><span>{{ actor.clothing_size }}</span>
          </p>
        </div>
      </div>
      <div class="line" />
      <div class="grid">
        <div class="col-12 md:col-6">
          <h2>Umiejętności</h2>
          <ul>
            <span v-for="(type, i) in actor.skills" :key="i">
              <li class="md:pb-3">
                <span>{{ type }}</span>
              </li>
            </span>
            <li
              v-if="
                Array.isArray(actor.driving_licence) &&
                actor.driving_licence.length > 0
              "
              class="md:pb-3"
            >
              <span>Prawo jazdy: </span>
              <span v-for="(type, i) in actor.driving_licence" :key="i">{{
                i === actor.driving_licence.length - 1 ? type : type + ", "
              }}</span>
            </li>
            <span v-else>Prawo jazdy: brak</span>
          </ul>
        </div>
        <div class="col-12 md:col-6 py-0 md:py-2">
          <h2>Informacje dodatkowe</h2>
          <p>
            <span class="bold-text">Inne agencje castingowe: </span
            ><span v-for="(type, i) in actor.other_casting_agencies" :key="i">{{
              i === actor.other_casting_agencies.length - 1 ? type : type + ", "
            }}</span>
          </p>
          <p>
            <span class="bold-text">Udział w bieliźnie: </span
            ><span>{{ actor.underwear_performance }}</span>
          </p>
          <p>
            <span class="bold-text">Negatywne role: </span
            ><span>{{ actor.negative_role }}</span>
          </p>
          <p>
            <span class="bold-text">Niekarany/a: </span
            ><span>{{ actor.no_criminal_record }}</span>
          </p>
          <p>
            <span class="bold-text">Data zapisu: </span
            ><span>{{ actor.enrollment_date }}</span>
          </p>
        </div>
      </div>
      <div
        class="line"
        v-if="actor.actor_page || actor.different_links?.length > 0"
      />
      <div class="grid">
        <div class="col-12 md:col-6 py-0 md:py-2" v-if="actor.actor_page">
          <h2>Strona aktora</h2>
          <p>
            <span class="bold-text">Adres: </span
            ><a :href="actor.actor_page">{{ actor.actor_page }}</a>
          </p>
          <p v-if="actor.polish_movie">
            <span class="bold-text">Filmpolski.pl: </span
            ><a :href="actor.polish_movie">{{ actor.polish_movie }}</a>
          </p>
        </div>
        <div
          class="col-12 md:col-6 py-0 md:py-2"
          v-if="actor.different_links?.length > 0"
        >
          <h2>Dodatkowe linki</h2>
          <p v-for="link in actor.different_links" :key="link">
            <span class="bold-text">Link: </span><a :href="link">{{ link }}</a>
          </p>
        </div>
      </div>
      <div class="line" />
      <div class="grid">
        <div class="col">
          <h2>Uwagi</h2>
          <p>{{ actor.remarks }}</p>
        </div>
      </div>
    </template>
  </Card>
</template>
<script>
import { differenceInYears, format } from "date-fns";
export default {
  name: "Profile",
  props: ["actor"],
  data() {
    return {
      birthDay: "",
      birthMonth: "",
      birthYear: "",
      error: "",
    };
  },
  methods: {
    getAge(dateString) {
      const currentDate = new Date();
      const birthdayDate = new Date(dateString);
      return differenceInYears(currentDate, birthdayDate);
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      return format(date, "dd/MM/yyy");
    },
  },
};
</script>
<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main";
@import "../styles/main_page";

.bold-text {
  font-weight: bold;
}

ul {
  list-style: none;
  padding: 0;
  li {
    margin-bottom: 0.25rem;
  }
}

li::before {
  content: "• ";
  color: $primary;
}

.edit-btn {
  height: 3rem;
}
span,
a {
  word-break: break-word;
}
</style>
