module.exports = Object.freeze({
  height: "height",
  weight: "weight",
  chest: "chest",
  waist: "waist",
  hips: "hips",
  collar: "collar",
  shoe_number: "shoe_number",
  age: "age",
});
