import { createApp } from "vue";
import App from "./App.vue";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import axios from "axios";
import router from "./router/index";
import { store } from "./vuex/store";
import VueLazyload from "vue-lazyload";
const loadimage = require("./assets/spinner.gif");

import "/node_modules/primeflex/primeflex.css";

import "primevue/resources/themes/saga-orange/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import { registerGlobalComponents } from "./registerGlobalComponents";

const app = createApp(App);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      router.currentRoute.value.name !== "LoginForm"
    ) {
      store.commit("admins/CLEAR_USER_DATA");
    }
    return Promise.reject(error);
  }
);

app.use(PrimeVue, {
  locale: {
    monthNames: [
      "Styczeń",
      "Luty",
      "Marzec",
      "Kwiecień",
      "Maj",
      "Czerwiec",
      "Lipiec",
      "Sierpień",
      "Wrzesień",
      "Październik",
      "Listopad",
      "Grudzień",
    ],
  },
});
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueLazyload, {
  preLoad: 1.3,
  loading: loadimage,
  attempt: 1,
});
registerGlobalComponents(app);

app.use(router);
app.use(store);

app.mount("#app");
