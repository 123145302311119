<template>
  <div class="offer-container mb-5">
    <h2 class="mt-6">Oferta</h2>
    <p class="mb-3 text-sm font-bold">
      Materiały udostępnione w tej ofercie są poufne, stanowią własność Agencji
      Zgrani, nie mogą być powielane i wykorzystane w celach innych, niż
      ustalone zlecenie.
    </p>
    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.5em" />
    <DataTable :value="actors" :scrollable="true">
      <template #empty> Brak aktorów w ofercie. </template>
      <Column header="Zdjęcie">
        <template #body="slotProps">
          <img
            :src="
              getProfilePic(
                slotProps.data.profilePic,
                slotProps.data.photos
              )
            "
            alt="Actor image"
            height="120"
            width="120"
            class="border-circle fit"
          />
        </template>
      </Column>
      <Column field="first_name" header="Imię">
        <template #body="slotProps">
          {{ slotProps.data.first_name }}
        </template>
      </Column>
      <Column field="last_name" header="Nazwisko">
        <template #body="slotProps">
          {{ slotProps.data.last_name }}
        </template>
      </Column>
      <Column field="last_name" header="Wiek">
        <template #body="slotProps">
          {{ getAge(slotProps.data.birthday) }}
        </template>
      </Column>
      <Column field="last_name" header="Wizytówka">
        <template #body="slotProps">
           <Button
           type="button"
                @click="navigateToVideo(slotProps.data.business_card_link)"
                class="p-button-secondary"
                target="_blank"
              >
                <i class="pi pi-video"></i>
        </Button>
        </template>
      </Column>
      <Column frozen alignFrozen="right" class="see-profile-col">
        <template #body="slotProps">
          <Button
            type="button"
            class="mr-3 action-btn orange-btn hidden lg:block"
            @click="seeProfile(slotProps.data._id)"
            >Zobacz profil</Button
          >
          <Button
            icon="pi pi-user"
            @click="seeProfile(slotProps.data._id)"
            class="p-button-rounded flex align-items-center orange-btn lg:hidden"
          />
        </template>
      </Column>
    </DataTable>
    <Dialog
      v-model:visible="display"
      :style="{
        fontFamily: 'Roboto, sans-serif',
        width: getDialogWidth
      }"
      :modal="true"
    >
      <template #header class="pb-0">
        <h2 class="ml-2 mr-2 mb-0 mt-0">
          {{ modalActor.first_name }} {{ modalActor.last_name }}
        </h2>
      </template>

      <div class="profile-container ml-2 mr-2">
        <profile-photos
          :photos="modalSelectedImages"
          :inOffer="true"
        ></profile-photos>
        <div class="line mt-4" />
        <div v-if="actorVideos">
          <div>
            <h3 class="mb-3">Linki do video</h3>
          </div>
          <div class="flex flex-wrap">
            <a
              :href="video"
              v-for="video in actorVideos"
              :key="video"
              target="_blank"
              class="video-link mr-3 mb-2"
            >
              <Button type="button" class="p-button-secondary">
                <i class="pi pi-video"></i>
              </Button>
            </a>
          </div>
        </div>
        <div class="line">
          <h3>Miejscowość: {{ modalActor.city_of_residence }}</h3>
        </div>
        <Card class="main-card mt-5 px-2 md:px-4 mb-4">
          <template #content>
            <div class="block">
              <div class="w-full">
                <h3 class="mb-0">Dane</h3>
              </div>
              <div class="w-full">
                <div class="inline-block w-full">
                  <p>
                    <span class="font-bold">Wiek: </span
                    ><span>{{ getAge(modalActor.birthday) }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Doświadczenie przed kamerą: </span
                    ><span>{{ modalActor.camera_experience }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Doświadczenie teatralne: </span
                    ><span>{{ modalActor.theatrical_experience }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Filmpolski.pl: </span
                    ><a :href="modalActor.polish_movie">{{ modalActor.polish_movie }}</a>
                  </p>
                  <p>
                    <span class="font-bold">Strona aktora: </span
                    ><a :href="modalActor.actor_page">{{ modalActor.actor_page }}</a>
                  </p>
                  <p>
                    <span class="font-bold">Umiejętności: </span
                    ><ul>
            <span v-for="(type, i) in modalActor.skills" :key="i">
              <li class="md:pb-3">
                <span>{{ type }}</span>
              </li>
            </span>
            <li
              v-if="
                Array.isArray(modalActor.driving_licence) &&
                modalActor.driving_licence.length > 0
              "
              class="md:pb-3"
            >
              <span>Prawo jazdy: </span>
              <span v-for="(type, i) in modalActor.driving_licence" :key="i">{{
                i === modalActor.driving_licence.length - 1 ? type : type + ", "
              }}</span>
            </li>
            <span v-else>Prawo jazdy: brak</span>
          </ul>
                  </p>
                </div>
              </div>
              <div class="w-full">
                <h3 class="mb-0">Aparycja</h3>
              </div>
              <div class="w-full flex flex-wrap">
                <div class="block mr-2">
                  <p>
                    <span class="font-bold">Długość włosów: </span
                    ><span>{{ modalActor.hair_length }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Kolor włosów: </span
                    ><span
                      v-for="(type, i) in modalActor.hair_color"
                      :key="i"
                      >{{ type }}{{ getActorFeatureSplitter(modalActor.hair_color, i) }}</span
                    >
                  </p>
                  <p>
                    <span class="font-bold">Kolor oczu: </span
                    ><span
                      v-for="(type, i) in modalActor.eye_color"
                      :key="i"
                      >{{ type }}{{ getActorFeatureSplitter(modalActor.eye_color, i) }}</span
                    >
                  </p>
                  <p>
                    <span class="font-bold">Kolor skóry: </span
                    ><span>{{ modalActor.skin_color }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Znaki szczególne: </span
                    ><span
                      v-for="(type, i) in modalActor.physical_characteristics"
                      :key="i"
                      >{{ type }}{{ getActorFeatureSplitter(modalActor.physical_characteristics, i) }}</span
                    >
                  </p>
                  <p>
                    <span class="font-bold">Wzrost: </span
                    ><span>{{ modalActor.height }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Waga: </span
                    ><span>{{ modalActor.weight }}</span>
                  </p>
                </div>
                <div class="block">
                  <p>
                    <span class="font-bold">Klatka: </span
                    ><span>{{ modalActor.chest }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Pas: </span
                    ><span>{{ modalActor.waist }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Biodra: </span
                    ><span>{{ modalActor.hips }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Kołnierz: </span
                    ><span>{{ modalActor.collar }}</span>
                  </p>
                  <p>
                    <span class="font-bold">But: </span
                    ><span>{{ modalActor.shoe_number }}</span>
                  </p>
                  <p>
                    <span class="font-bold">Rozmiar odzieży: </span
                    ><span>{{ modalActor.clothing_size }}</span>
                  </p>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </Dialog>
  </div>
</template>
<script>
import ProfilePhotos from "./ProfilePhotos.vue";
import defaultAvatar from "../assets/profile-pic.svg";
export default {
  name: "Offer",
  components: { ProfilePhotos },
  data() {
    return {
      defaultAvatar,
      actors: [],
      modalActor: {},
      error: "",
      display: false,
      loading: false,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    navigateToVideo(link) {
      window.open(link, "_blank");
    },
    seeProfile(id) {
      this.actorWithSelectedImages = this.actors.find(
        (actor) => actor._id === id
      );

      this.modalActor = this.actorWithSelectedImages;

      this.modalSelectedImages = this.actorWithSelectedImages.selectedImages;

      this.display = true;
    },
    getProfilePic(profilePic, photos) {
      if (!profilePic || (!profilePic.url && !photos.length)) {
        return this.defaultAvatar;
      }
      return (
        profilePic.url || photos[0].url || photos[1].url || this.defaultAvatar
      );
    },
    getAge(dateOfBirth) {
      const birthday = new Date(dateOfBirth);
      const today = new Date();
      return dateOfBirth
        ? Math.round(today.getFullYear() - birthday.getFullYear())
        : null;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getActorFeatureSplitter(feature, index) {
      return index + 1 !== feature.length ? ", " : "";
    },
  },
  computed: {
    getDialogWidth() {
      return this.windowWidth > 1000 ? "65vw" : "95vw";
    },
    actorVideos() {
      let videos = [];
      const videoTypes = [
        "business_card_link",
        "demo_link",
        "monologue_advertisement_link",
        "archival",
        "additional_field",
        "second_additional_field",
      ];
      if (this.modalActor) {
        videoTypes.forEach((type) => {
          if (this.modalActor[type]) {
            videos.push(this.modalActor[type]);
          }
        });
        if (this.modalActor.video_links?.length > 0) {
          const videoUrls = this.modalActor.video_links;
          videos.push(...videoUrls);
        }
      }
      return videos;
    },
  },
  async created() {
    if (!this.$route.params.offerActors) {
      this.$router.push({ name: "AccessCode" });
    }
    try {
      this.loading = true;
      this.actors = JSON.parse(this.$route.params.offerActors).filter(
        (actor) => actor._id
      );
    } catch (err) {
      this.error = err;
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main_page";
@import "../styles/main";

.fit {
  object-fit: cover;
  width: 120px;
  height: 120px;
}
.offer-container {
  @include global-spacing;
  font-family: "Roboto", sans-serif;
}

::v-deep(.p-frozen-column) {
  &.see-profile-col {
    min-width: 200px;
    @media only screen and (max-width: 64em) {
      max-width: 70px;
      min-width: unset;
    }
  }
}
span,
a {
  word-break: break-word;
}
</style>
