<template>
  <div>
    <div class="flex align-items-center mb-5">
      <Button
        @click="goBack"
        class="back-btn flex align-items-center justify-content-center"
      >
        <i id="back-icon" class="pi pi-arrow-left"></i>
      </Button>
      <div class="ml-4 font-bold text-2xl">Powrót</div>
    </div>
    <Card class="main-card mt-5 md:px-3 px-2 mb-4">
      <template #title>
        <div class="line">
          <div class="pt-4 pb-3">Informacje ogólne</div>
        </div>
      </template>
      <template #content>
        <form ref="profileForm">
          <div class="grid">
            <div class="col-12 md:col-3 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">ID</div>
              <InputNumber
                id="identifierNumber"
                v-model="currentActor.identifierNumber"
                placeholder="Unikalne ID"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Imię*</div>
              <InputText
                placeholder="Imię"
                id="first_name"
                type="text"
                v-model="currentActor.first_name"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Nazwisko*</div>
              <InputText
                placeholder="Nazwisko"
                id="last_name"
                type="text"
                v-model="currentActor.last_name"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Numer telefonu*</div>
              <InputText
                placeholder="Numer Kontaktowy"
                id="phone_number"
                type="text"
                v-model="currentActor.phone_number"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Adres e-mail</div>
              <InputText
                placeholder="Adres E-mail"
                id="mail"
                type="text"
                v-model="currentActor.mail"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Data urodzenia</div>
              <Calendar
                v-model="currentActor.birthday"
                :yearNavigator="true"
                yearRange="1900:2030"
                placeholder="Data urodzenia"
                dateFormat="dd.mm.yy"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Wzrost</div>
              <InputNumber
                id="height"
                suffix=" cm"
                v-model="currentActor.height"
                placeholder="Wzrost"
              />
            </div>
            <div class="col-12 lg:col-6">
              <div class="text-base font-bold mb-2 ml-4">Umiejętności</div>
              <Chips
                id="skills"
                v-tooltip.top="'Wciśnij Enter aby dodać element'"
                v-model="currentActor.skills"
                placeholder="Dodaj"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">Doświadczenie TV</div>
              <InputText
                placeholder="Dodaj doświadczenie"
                id="camera_experience"
                type="text"
                v-model="currentActor.camera_experience"
              />
            </div>
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">
                Doświadczenie teatralne
              </div>
              <InputText
                placeholder="Dodaj doświadczenie"
                id="theatrical_experience"
                type="text"
                v-model="currentActor.theatrical_experience"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Kategoria artysty</div>
              <MultiSelect
                v-model="currentActor.artist_type"
                :options="artist_category_enum"
                placeholder="Wybierz"
                display="chip"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Płeć</div>
              <Dropdown
                id="gender"
                v-model="currentActor.gender"
                :options="gender_enum"
                placeholder="Wybierz płeć"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Miejsce urodzenia</div>
              <InputText
                placeholder="Miejsce urodzenia"
                id="place_of_birth"
                type="text"
                v-model="currentActor.place_of_birth"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">
                Numer do bliskiej osoby
              </div>
              <InputText
                placeholder="Numer do osoby bliskiej"
                id="friend_phone_number"
                type="text"
                v-model="currentActor.friend_phone_number"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">
                Miejsce zamieszkania
              </div>
              <InputText
                placeholder="Miejsce zamieszkania"
                id="place_of_residence"
                type="text"
                v-model="currentActor.place_of_residence"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">
                Miejscowość zamieszkania
              </div>

              <InputText
                placeholder="Miejscowość zamieszkania"
                id="city_of_residence"
                type="text"
                v-model="currentActor.city_of_residence"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">
                Miejsce zameldowania
              </div>
              <InputText
                placeholder="Miejsce zameldowania"
                id="place_of_registered_residence"
                type="text"
                v-model="currentActor.place_of_registered_residence"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">
                Miejscowość zameldowania
              </div>
              <InputText
                placeholder="Miejscowość zameldowania"
                id="city_of_registered_residence"
                type="text"
                v-model="currentActor.city_of_registered_residence"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-4 lg:col-2">
              <div class="text-base font-bold mb-2 ml-4">Województwo</div>
              <Dropdown
                id="province"
                v-model="currentActor.province"
                :options="province_enum"
                placeholder="Wybierz"
              />
            </div>
            <div class="col-12 md:col-4 lg:col-2">
              <div class="text-base font-bold mb-2 ml-4">Waga</div>
              <InputNumber
                id="weight"
                v-model="currentActor.weight"
                suffix=" kg"
                placeholder="w kg"
              />
            </div>
            <div class="col-12 md:col-4 lg:col-2">
              <div class="text-base font-bold mb-2 ml-4">Klatka</div>
              <InputNumber
                id="chest"
                v-model="currentActor.chest"
                suffix=" cm"
                placeholder="w cm"
              />
            </div>
            <div class="col-12 md:col-4 lg:col-2">
              <div class="text-base font-bold mb-2 ml-4">Pas</div>
              <InputNumber
                id="waist"
                v-model="currentActor.waist"
                suffix=" cm"
                placeholder="w cm"
              />
            </div>
            <div class="col-12 md:col-4 lg:col-2">
              <div class="text-base font-bold mb-2 ml-4">Biodra</div>
              <InputNumber
                id="hips"
                v-model="currentActor.hips"
                suffix=" cm"
                placeholder="w cm"
              />
            </div>
            <div class="col-12 md:col-4 lg:col-2">
              <div class="text-base font-bold mb-2 ml-4">Kołnierz</div>
              <InputNumber
                id="collar"
                suffix=" cm"
                v-model="currentActor.collar"
                placeholder="w cm"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Numer buta</div>
              <InputNumber
                id="shoe_number"
                v-model="currentActor.shoe_number"
                placeholder="EU"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Rozmiar odzieży</div>
              <Dropdown
                id="clothing_size"
                v-model="currentActor.clothing_size"
                :options="clothing_size_enum"
                placeholder="Wybierz"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Długość włosów</div>
              <Dropdown
                id="hair_length"
                v-model="currentActor.hair_length"
                :options="hair_length_enum"
                placeholder="Wybierz"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Kolor włosów</div>
              <MultiSelect
                v-model="currentActor.hair_color"
                :options="hair_color_enum"
                placeholder="Wybierz"
                display="chip"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-4">
              <div class="text-base font-bold mb-2 ml-4">Kolor oczu</div>
              <MultiSelect
                v-model="currentActor.eye_color"
                :options="eye_color_enum"
                placeholder="Wybierz"
                display="chip"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-4">
              <div class="text-base font-bold mb-2 ml-4">Kolor skóry</div>
              <Dropdown
                id="skin_color"
                v-model="currentActor.skin_color"
                :options="skin_color_enum"
                placeholder="Wybierz"
              />
            </div>
            <div class="col-12 lg:col-4">
              <div class="text-base font-bold mb-2 ml-4">Znaki szczególne</div>
              <Chips
                id="physical_characteristics"
                v-tooltip.top="'Wciśnij Enter aby dodać element'"
                v-model="currentActor.physical_characteristics"
                placeholder="Dodaj"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Prawo jazdy</div>
              <MultiSelect
                v-model="currentActor.driving_licence"
                :options="driving_licence_enum"
                placeholder="Wybierz"
                display="chip"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">
                Inne agencje castingowe
              </div>
              <InputText
                placeholder="Wprowadź agencje"
                id="other_casting_agencies"
                type="text"
                v-model="currentActor.other_casting_agencies"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">
                Udział w bieliźnie
              </div>
              <Dropdown
                id="underwear_performance"
                v-model="currentActor.underwear_performance"
                :options="yes_no_enum"
                placeholder="Wybierz"
              />
            </div>
            <div class="col-12 md:col-6 lg:col-3">
              <div class="text-base font-bold mb-2 ml-4">Niekarany/a</div>
              <Dropdown
                id="no_criminal_record"
                v-model="currentActor.no_criminal_record"
                :options="yes_no_enum"
                placeholder="Wybierz"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">Data zapisu</div>
              <InputMask
                mask="99.99.9999"
                id="enrollment_date"
                v-model="currentActor.enrollment_date"
                placeholder="dd.mm.yyyy"
                slotChar="dd.mm.yyyy"
              />
            </div>
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">Negatywne role</div>
              <Dropdown
                id="negative_role"
                v-model="currentActor.negative_role"
                :options="yes_no_enum"
                placeholder="Wybierz"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">Uwagi</div>
              <InputText
                placeholder="Dodaj uwagi"
                id="remarks"
                type="text"
                v-model="currentActor.remarks"
              />
            </div>
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">Link do wizytówki</div>
              <InputText
                placeholder="Dodaj link"
                id="business_card_link"
                type="text"
                v-model="currentActor.business_card_link"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">Link do demo</div>
              <InputText
                placeholder="Dodaj link"
                id="demo_link"
                type="text"
                v-model="currentActor.demo_link"
              />
            </div>
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">Link do monologu</div>
              <InputText
                placeholder="Dodaj link"
                id="monologue_advertisement_link"
                type="text"
                v-model="currentActor.monologue_advertisement_link"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">
                Link do dodatkowego video - 1
              </div>
              <InputText
                placeholder="Dodaj link"
                id="additional_field"
                type="text"
                v-model="currentActor.additional_field"
              />
            </div>
            <div class="col-12 md:col-6">
              <div class="text-base font-bold mb-2 ml-4">
                Link do dodatkowego video - 2
              </div>
              <InputText
                placeholder="Dodaj link"
                id="second_additional_field"
                type="text"
                v-model="currentActor.second_additional_field"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <div class="text-base font-bold mb-2 ml-4">
                Link do video archivalnego
              </div>
              <InputText
                placeholder="Dodaj link"
                id="archival"
                type="text"
                v-model="currentActor.archival"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <div class="text-base font-bold mb-2 ml-4">Filmpolski.pl</div>
              <InputText
                placeholder="Dodaj link"
                id="polish_movie"
                type="text"
                v-model="currentActor.polish_movie"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <div class="text-base font-bold mb-2 ml-4">Strona aktora</div>
              <InputText
                placeholder="Dodaj link"
                id="actor_page"
                type="text"
                v-model="currentActor.actor_page"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <div class="text-base font-bold mb-2 ml-4">Linki do filmów:</div>
              <Chips
                id="video_links"
                v-tooltip.top="'Wciśnij Enter aby dodać element'"
                v-model="currentActor.video_links"
                placeholder="Dodaj link"
              >
                <template #chip="slotProps">
                  <div @click.stop>
                    <span>{{ slotProps.value }}</span>
                  </div>
                </template></Chips
              >
            </div>
          </div>
          <div class="grid">
            <div class="col">
              <div class="text-base font-bold mb-2 ml-4">Inne linki:</div>
              <Chips
                id="different_links"
                v-tooltip.top="'Wciśnij Enter aby dodać element'"
                v-model="currentActor.different_links"
                placeholder="Dodaj link"
              >
                <template #chip="slotProps">
                  <div @click.stop>
                    <span>{{ slotProps.value }}</span>
                  </div>
                </template></Chips
              >
            </div>
          </div>
          <div align="center" class="mt-4">
            <slot name="submit"></slot>
          </div>
          <Toast position="bottom-right" />
        </form>
      </template>
    </Card>
  </div>
</template>
<script>
import {
  artist_category_enum,
  gender_enum,
  clothing_size_enum,
  hair_length_enum,
  hair_color_enum,
  eye_color_enum,
  skin_color_enum,
  driving_licence_enum,
  province_enum,
  yes_no_enum,
} from "../const/enums";
export default {
  name: "ProfileForm",
  props: ["actor", "backTo"],
  data() {
    return {
      currentActor: {},
      artist_category_enum,
      gender_enum,
      clothing_size_enum,
      hair_length_enum,
      hair_color_enum,
      eye_color_enum,
      skin_color_enum,
      driving_licence_enum,
      province_enum,
      yes_no_enum,
    };
  },
  created() {
    this.currentActor = { ...this.actor };

    if (this.currentActor.birthday) {
      this.currentActor.birthday = new Date(this.currentActor.birthday);
    }
  },
  watch: {
    currentActor: {
      handler: function () {
        this.$emit("actorChanged", this.currentActor);
      },
      deep: true,
    },
  },
  methods: {
    goBack() {
      this.$router.push(this.backTo);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/_variables";
@import "../styles/main";
@import "../styles/main_page";
@mixin radius-input {
  border-radius: 1.875rem;
  border: 1px solid $senary;
  width: 100%;
}

::v-deep(.p-dropdown .p-dropdown-label) {
  border: none;
}

::v-deep(.p-dropdown.p-dropdown-label) {
  border: none;
}
::v-deep(.p-inputtext) {
  @include radius-input;
  padding: 1rem 1.5rem;
}
::v-deep(.p-dropdown) {
  @include radius-input;
  padding-right: 0.75rem;
}

::v-deep(.p-multiselect) {
  @include radius-input;
  padding: 0.75rem 1.25rem;
}

::v-deep(
    .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label
  ) {
  padding: 0rem;
}

::v-deep(.p-multiselect .p-multiselect-label) {
  padding: 0.25rem;
}

::v-deep(.p-chips),
::v-deep(.p-inputnumber) {
  width: 100%;
}

::v-deep(.p-chips .p-chips-multiple-container) {
  padding: 0.75rem 1.25rem 0.5rem 1.25rem;
}

::v-deep(.p-chips .p-chips-multiple-container .p-chips-token) {
  margin-bottom: 0.25rem;
}
::v-deep(.p-chips .p-chips-multiple-container .p-chips-input-token input) {
  margin-left: 0.15rem;
}
.grid {
  margin-bottom: 0.5rem;
  @media only screen and (max-width: 48em) {
    margin-bottom: 0;
  }
}

.back-btn {
  background-color: $primary;
  display: block;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
