import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { FilterMatchMode } from "primevue/api";

export const actors = {
  namespaced: true,
  state: () => ({
    initialParams: {
      first: 0,
      limit: 20,
      filters: {
        identifierNumber: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        first_name: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        last_name: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        phone_number: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        mail: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        age: { value: null, matchMode: FilterMatchMode.BETWEEN },
        birthday: { value: null, matchMode: FilterMatchMode.BETWEEN },
        height: { value: null, matchMode: FilterMatchMode.BETWEEN },
        skills: { value: null, matchMode: FilterMatchMode.IN },
        driving_licence: { value: null, matchMode: FilterMatchMode.IN },
        province: { value: null, matchMode: FilterMatchMode.IN },
        camera_experience: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        theatrical_experience: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        artist_type: { value: null, matchMode: FilterMatchMode.IN },
        gender: { value: null, matchMode: FilterMatchMode.IN },
        place_of_residence: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        city_of_residence: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        place_of_registered_residence: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        city_of_registered_residence: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        place_of_birth: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        friend_phone_number: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        weight: { value: null, matchMode: FilterMatchMode.BETWEEN },
        chest: { value: null, matchMode: FilterMatchMode.BETWEEN },
        waist: { value: null, matchMode: FilterMatchMode.BETWEEN },
        hips: { value: null, matchMode: FilterMatchMode.BETWEEN },
        collar: { value: null, matchMode: FilterMatchMode.BETWEEN },
        shoe_number: { value: null, matchMode: FilterMatchMode.BETWEEN },
        clothing_size: { value: null, matchMode: FilterMatchMode.IN },
        hair_length: { value: null, matchMode: FilterMatchMode.IN },
        hair_color: { value: null, matchMode: FilterMatchMode.IN },
        eye_color: { value: null, matchMode: FilterMatchMode.IN },
        skin_color: { value: null, matchMode: FilterMatchMode.IN },
        physical_characteristics: {
          value: null,
          matchMode: FilterMatchMode.IN,
        },
        underwear_performance: { value: null, matchMode: FilterMatchMode.IN },
        no_criminal_record: { value: null, matchMode: FilterMatchMode.IN },
        negative_role: { value: null, matchMode: FilterMatchMode.IN },
        remarks: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      page: 1,
      stringQuery: [],
      enumQuery: [],
      rangeQuery: [],
    },
    actor: null,
    selectedActors: [],
    params: {
      first: 0,
      limit: 20,
      filters: {
        identifierNumber: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        first_name: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        last_name: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        phone_number: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        mail: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        age: { value: null, matchMode: FilterMatchMode.BETWEEN },
        birthday: { value: null, matchMode: FilterMatchMode.BETWEEN },
        height: { value: null, matchMode: FilterMatchMode.BETWEEN },
        skills: { value: null, matchMode: FilterMatchMode.IN },
        driving_licence: { value: null, matchMode: FilterMatchMode.IN },
        province: { value: null, matchMode: FilterMatchMode.IN },
        camera_experience: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        theatrical_experience: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        artist_type: { value: null, matchMode: FilterMatchMode.IN },
        gender: { value: null, matchMode: FilterMatchMode.IN },
        place_of_residence: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        city_of_residence: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        place_of_registered_residence: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        city_of_registered_residence: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        place_of_birth: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        friend_phone_number: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        weight: { value: null, matchMode: FilterMatchMode.BETWEEN },
        chest: { value: null, matchMode: FilterMatchMode.BETWEEN },
        waist: { value: null, matchMode: FilterMatchMode.BETWEEN },
        hips: { value: null, matchMode: FilterMatchMode.BETWEEN },
        collar: { value: null, matchMode: FilterMatchMode.BETWEEN },
        shoe_number: { value: null, matchMode: FilterMatchMode.BETWEEN },
        clothing_size: { value: null, matchMode: FilterMatchMode.IN },
        hair_length: { value: null, matchMode: FilterMatchMode.IN },
        hair_color: { value: null, matchMode: FilterMatchMode.IN },
        eye_color: { value: null, matchMode: FilterMatchMode.IN },
        skin_color: { value: null, matchMode: FilterMatchMode.IN },
        physical_characteristics: {
          value: null,
          matchMode: FilterMatchMode.IN,
        },
        underwear_performance: { value: null, matchMode: FilterMatchMode.IN },
        no_criminal_record: { value: null, matchMode: FilterMatchMode.IN },
        negative_role: { value: null, matchMode: FilterMatchMode.IN },
        remarks: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      page: 1,
      stringQuery: [],
      enumQuery: [],
      rangeQuery: [],
    },
    avatar: null,
  }),
  getters: getters,
  mutations: mutations,
  actions: actions,
};
