<template>
  <div>
    <div
      class="actor-item"
      v-for="actor in actorsWithSelectedImages"
      :key="actor._id"
    >
      <header>{{ actor.first_name }} {{ actor.last_name }}</header>
      <div class="actor-pics">
        <div class="pic-item" v-for="pic in actor.photos" :key="pic.url">
          <img
            v-if="pic.url"
            @click="togglePhoto(actor._id, pic.url)"
            v-lazy="pic.url"
            alt="actor picture"
            :class="{ selected: photoExists(actor._id, pic.url) }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    actors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      actorsWithSelectedImages: [],
    };
  },
  computed: {},
  methods: {
    ...mapActions("actors", ["setSelectedActors"]),
    async togglePhoto(actorId, url) {
      const photoExists = this.photoExists(actorId, url);

      const actor = this.getActor(actorId);

      if (photoExists) {
        this.removePhotoAndReplaceActor(actorId, actor, url);
      } else {
        this.addPhotoAndReplaceActor(actorId, actor, url);
      }

      await this.setSelectedActors(this.actorsWithSelectedImages);
    },
    photoExists(actorId, url) {
      const actor = this.getActor(actorId);

      const photoExists = actor.selectedPhotos.some(
        (photo) => photo.url === url
      );

      return photoExists;
    },
    removePhotoAndReplaceActor(actorId, actor, url) {
      const newSelectedPhotos = actor.selectedPhotos.filter(
        (photo) => photo.url !== url
      );
      actor.selectedPhotos = newSelectedPhotos;
      this.replaceActor(actorId, actor);
    },
    addPhotoAndReplaceActor(actorId, actor, url) {
      actor.selectedPhotos.push({ url: url });
      this.replaceActor(actorId, actor);
    },
    replaceActor(actorId, actor) {
      this.actorsWithSelectedImages = this.actorsWithSelectedImages.map(
        (actorToBeReplaced) => {
          return actorToBeReplaced._id === actorId ? actor : actorToBeReplaced;
        }
      );
    },
    getActor(actorId) {
      const actor = this.actorsWithSelectedImages.find(
        (actor) => actor._id === actorId
      );

      return actor;
    },
  },
  mounted() {
    this.actorsWithSelectedImages = this.actors.map((actor) => {
      return { ...actor, selectedPhotos: [] };
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables";
@import "../styles/main";
.actor-item {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 1rem;
  }
  &:not(:first-child) {
    margin-top: 1rem;
  }
  .actor-pics {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    .pic-item {
      max-width: 10%;
      img {
        width: 80%;
        padding: 0.2rem;
        &.selected {
          border: 4px solid $primary;
        }
      }
    }
  }
}
</style>