<template>
  <div>
    <div class="flex align-items-center justify-content-center">
      <Card class="card mt-8 mb-8">
        <template #title>
          <div class="line ml-4 mr-4">
            <div class="pt-4 pb-3">Przejdź do oferty</div>
          </div>
        </template>
        <template #content>
          <h5 class="code-label ml-6">Podaj kod dostępu</h5>
          <InputText class="text-input pl-4 ml-4 mr-4" v-model="codeValue" />
          <p class="input-error ml-6" v-if="codeError">{{ codeError }}</p>
          <div class="btn-container">
            <Button
              class="p-button-rounded submit-btn mt-3 ml-4 mr-4 pl-4"
              type="submit"
              :loading="loading"
              @click="submit"
            >
              Zatwierdź
            </Button>
          </div>
        </template>
      </Card>
    </div>
    <Toast position="bottom-center" />
  </div>
</template>
<script>
import errorCodes from "../const/errorCodes";
import OfferService from "../services/OfferService";
export default {
  name: "AccessCode",
  data() {
    return {
      codeValue: "",
      codeError: "",
      loading: false,
    };
  },
  methods: {
    async submit(e) {
      e.preventDefault();
      this.loading = true;
      this.codeError = "";

      try {
        const accessCodeRegex = /^[a-z0-9]{10}$/gi;
        if (!this.codeValue) {
          this.codeError = "Kod jest wymagany!";
          return;
        }
        if (!accessCodeRegex.test(this.codeValue)) {
          this.codeError =
            "Kod musi składać się z liczb, liter i mieć 10 znaków";
          return;
        }

        const offerActors = await OfferService.checkAccessCode(this.codeValue);

        this.$router.push({
          name: "Offer",
          params: { offerActors: JSON.stringify(offerActors) },
        });
      } catch (error) {
        this.error = errorCodes[error.errorCode];
        this.$toast.add({
          severity: "error",
          summary: "Wystąpił problem!",
          detail: this.error,
          life: 5000,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main";

.code-label {
  margin-top: -0.5rem;
}
</style>
