import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { actors } from "./actors/actors";
import { admins } from "./admins/admins";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

export const store = createStore({
  modules: {
    actors,
    admins,
  },
  state: {},
  mutations: { ...mutations },
  actions: { ...actions },
  getters: { ...getters },
  plugins: [createPersistedState()],
});
