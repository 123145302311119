<template>
  <div>
    <Toast position="bottom-right" />
    <div class="btn-container">
      <input
        type="file"
        name="actors"
        ref="file"
        accept=".csv"
        @change="onFilePicked"
        hidden
      />
      <Button
        type="button"
        label="Importuj z csv"
        class="action-btn white"
        iconPos="right"
        :loading="loading"
        @click="onPickFile"
      />
    </div>
  </div>
</template>

<script>
import ActorService from "../services/ActorService";
import errorCodes from "../const/errorCodes";

export default {
  name: "CSVButton",
  props: {
    getFilteredActors: { type: Function },
  },
  data() {
    return {
      file: "",
      error: "",
      success: "",
      loading: false,
    };
  },
  methods: {
    onPickFile() {
      this.$refs.file.click();
    },
    clearFileList(e) {
      this.file = "";
      let list = new DataTransfer();
      let file = new File(["content"], "");
      list.items.add(file);
      e.target.files = list.files;
    },
    async onFilePicked(e) {
      this.loading = true;
      try {
        const file = e.target.files[0];
        this.file = file;
        const res = await ActorService.upload(this.file);
        if (res === "OK") {
          this.success = "Dane zostały dodane";
          this.getFilteredActors();
          this.$toast.add({
            severity: "success",
            summary: "Baza aktorów została zaktualizowana!",
            life: 5000,
          });
          this.file = null;
        }
      } catch (error) {
        this.error = errorCodes[error.errorCode];
        this.$toast.add({
          severity: "error",
          summary: "Wystąpił problem!",
          detail: this.error,
          life: 5000,
        });
      } finally {
        this.clearFileList(e);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main";

.btn-container {
  display: flex;
}

.side-p {
  display: inline-block;
  height: 0.2rem;
}

button {
  @media screen and (max-width: 64em) {
    font-size: 60%;
    padding: 0.1rem 0.5rem !important;
    max-height: 1rem;
  }
}
</style>
