module.exports = Object.freeze({
  identifierNumber: "identifierNumber",
  last_name: "last_name",
  first_name: "first_name",
  remarks: "remarks",
  phone_number: "phone_number",
  mail: "mail",
  theatrical_experience: "theatrical_experience",
  camera_experience: "camera_experience",
  city_of_residence: "city_of_residence",
  city_of_registered_residence: "city_of_registered_residence",
  place_of_registered_residence: "place_of_registered_residence",
  business_card_link: "business_card_link",
  place_of_birth: "place_of_birth",
  friend_phone_number: "friend_phone_number",
  place_of_residence: "place_of_residence",
  other_casting_agencies: "other_casting_agencies",
  enrollment_date: "enrollment_date",
  birthday: "birthday",
  video_links: "video_links",
  polish_movie: "polish_movie",
});
