import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export const admins = {
  namespaced: true,
  state: () => ({ userData: null, offers: [] }),
  getters: getters,
  mutations: mutations,
  actions: actions,
};
