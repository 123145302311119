export default {
  actor(state) {
    return state.actor;
  },
  params(state) {
    return state.params;
  },
  avatar(state) {
    return state.avatar;
  },
  storeSelectedActors(state) {
    return state.selectedActors;
  },
};
