import AdminAuthService from "../../services/AdminAuthService";
import OfferService from "../../services/OfferService";

export default {
  async login({ commit }, [email, password]) {
    return await AdminAuthService.login(email, password).then((res) => {
      commit("SET_USER_DATA", res);
    });
  },
  async logout({ commit }) {
    return await AdminAuthService.logout().then(() => {
      commit("CLEAR_USER_DATA");
    });
  },

  async getAllOffers({ commit }) {
    const offers = await OfferService.getAllOffers();
    commit("SET_OFFERS", offers);
  },

  async addActorsToOffer(_, { offerId, actors }) {
    return await OfferService.addActorsToOffer(offerId, actors);
  },
};
