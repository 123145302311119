<template>
  <div class="profile-container mt-4" v-if="actor">
    <profile-form
      @actorChanged="onActorChange"
      :actor="actor"
      :backTo="{ name: 'MainPage' }"
    >
      <template v-slot:submit>
        <Button @click="addActor" :disabled="addedActor" class="action-btn"
          >Zapisz zmiany</Button
        >
      </template>
    </profile-form>
  </div>
</template>
<script>
import ProfileForm from "../../components/ProfileForm.vue";
import ActorService from "../../services/ActorService";
import { toastMixin } from "../../mixins/toastMixin.js";
import errorCodes from "../../const/errorCodes.js";
import { isUrl } from "../../helpers/isUrl";
export default {
  name: "EditProfile",
  components: { ProfileForm },
  mixins: [toastMixin],
  data() {
    return {
      urlFields: [
        { type: "polish_movie", name: "Filmpolski.pl" },
        { type: "actor_page", name: "Strona aktora" },
      ],
      addedActor: false,
      actor: {
        first_name: null,
        last_name: null,
        phone_number: null,
        mail: null,
        birthday: null,
        height: null,
        skills: null,
        camera_experience: null,
        theatrical_experience: null,
        artist_type: null,
        gender: null,
        place_of_birth: null,
        friend_phone_number: null,
        place_of_residence: null,
        city_of_residence: null,
        place_of_registered_residence: null,
        city_of_registered_residence: null,
        province: null,
        weight: null,
        chest: null,
        waist: null,
        hips: null,
        collar: null,
        shoe_number: null,
        clothing_size: null,
        hair_length: null,
        hair_color: null,
        eye_color: null,
        skin_color: null,
        physical_characteristics: null,
        driving_licence: null,
        other_casting_agencies: null,
        underwear_performance: null,
        no_criminal_record: null,
        enrollment_date: null,
        negative_role: null,
        remarks: null,
        business_card_link: null,
        video_links: null,
        profile_photo: null,
        photos_links: null,
      },
    };
  },
  methods: {
    validateActor() {
      this.validateUrls();
      const requiredFields = [
        { type: "first_name", name: "Imię" },
        { type: "last_name", name: "Nazwisko" },
        { type: "phone_number", name: "Numer telefonu" },
      ];
      requiredFields.forEach((field) => {
        if (!this.actor[field.type]) {
          throw { type: "required", field: field.name };
        }
      });
    },
    validateUrls() {
      this.urlFields.forEach((field) => {
        if (!isUrl(this.actor[field.type])) {
          throw { type: "invalidUrl", field: field.name };
        }
      });
    },
    async addActor() {
      try {
        this.validateActor();
        await ActorService.addActor(this.actor);
        this.addedActor = true;
        this.displayToast(
          "success",
          "Aktor został poprawnie dodany, za chwilę nastąpi przekierowanie do strony głównej!"
        );
        setTimeout(() => {
          this.$router.push({ name: "MainPage" });
        }, 3500);
      } catch (err) {
        if (err.type === "required") {
          this.displayToast("error", `Pole ${err.field} jest wymagane!`);
        } else {
          const error = errorCodes[err.errorCode];
          this.displayToast("error", error);
        }
      }
    },
    onActorChange(updatedActor) {
      this.actor = { ...updatedActor };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables";
@import "../../styles/main";
.profile-container {
  @include global-spacing;
  font-family: "Roboto", sans-serif;
}
</style>
