<template>
  <div class="profile-view">
    <Toast position="bottom-right" />
    <div class="profile-navigation" v-if="!editMode">
      <Button
        @click="goBack"
        class="back-btn flex align-items-center justify-content-center"
      >
        <i id="back-icon" class="pi pi-arrow-left"></i>
      </Button>
      <div
        class="profile-pic-wrapper"
        v-bind:style="{
          backgroundImage: 'url(' + profilePicOrDefaultAvatar() + ')',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }"
      ></div>
      <div class="profile-links">
        <router-link
          v-for="link in profileNavigationLinks"
          :key="link.name"
          :to="link.to"
          class="ml-3 md:ml-8"
        >
          {{ link.name }}
        </router-link>
      </div>
    </div>
    <div>
      <div class="profile-content pt-4" v-if="!editMode">
        <div class="actor-short-desc" v-if="actor">
          <h2 class="mb-1">{{ actor.first_name }} {{ actor.last_name }}</h2>
          <p class="mt-0">
            <span v-for="(type, index) in actor.artist_type" :key="index"
              >{{ type
              }}{{ index + 1 !== actor.artist_type.length ? " | " : "" }}</span
            >
          </p>
        </div>
        <div>
          <Button class="action-btn" @click="editProfile">Edytuj profil</Button>
        </div>
      </div>
      <router-view></router-view>
      <div align="left" class="my-4" v-if="!editMode">
        <Button class="action-btn" @click="shareProfile"
          >Udostępnij profil</Button
        >
      </div>
    </div>
    <Dialog v-model:visible="shareModal">
      <template #header>
        <h3 class="m-0 pr-2">Oferta wygenerowana</h3>
      </template>

      <p>
        Kod dostępu: <span class="font-bold"> {{ accessCode }}</span>
      </p>

      <template #footer>
        <Button
          label="Ok"
          icon="pi pi-check"
          autofocus
          @click="() => (shareModal = false)"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { toastMixin } from "../../mixins/toastMixin.js";
import OfferService from "../../services/OfferService";
import defaultAvatar from "../../assets/profile-pic.svg";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ProfileIndex",
  mixins: [toastMixin],
  data() {
    return {
      defaultAvatar,
      actorId: null,
      accessCode: null,
      shareModal: false,
      profileNavigationLinks: [
        {
          to: { name: "GeneralInformation" },
          name: "Informacje ogólne",
        },
        {
          to: { name: "Photos" },
          name: "Zdjęcia",
        },
        {
          to: { name: "Videos" },
          name: "Filmy",
        },
      ],
    };
  },
  methods: {
    ...mapActions("actors", { storeLoadActor: "loadActor" }),
    setNavigationLinksParams() {
      this.profileNavigationLinks.map((link) => {
        return {
          ...link,
          to: {
            ...this.to,
            id: this.actorId,
          },
        };
      });
    },
    editProfile() {
      this.$router.push({
        name: "EditProfile",
        params: { id: this.actorId },
      });
    },
    async shareProfile() {
      try {
        const accessCode = await OfferService.generateOffer([this.actorId]);
        if (accessCode) {
          this.shareModal = true;
          this.accessCode = accessCode;
        }
      } catch (err) {
        this.displayToast("error", "Wystąpił błąd podczas generowania oferty");
      }
    },
    goBack() {
      this.$router.push({ name: "MainPage" });
    },

    profilePicOrDefaultAvatar() {
      return this.profilePicUrl || this.defaultAvatar;
    },
  },
  computed: {
    editMode() {
      return this.$route.name === "EditProfile";
    },
    profilePicUrl() {
      const imgUrl =
        this.avatar?.url ||
        this.actor?.photos[0]?.url ||
        this.actor?.photos[1]?.url;
      return imgUrl;
    },
    ...mapGetters("actors", ["actor", "avatar"]),
  },
  async created() {
    const { id } = this.$route.params;
    this.actorId = id;
    await this.storeLoadActor(id);
    this.setNavigationLinksParams();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/main";

.profile-view {
  font-family: "Roboto", sans-serif;
  @include global-spacing;
  .profile-navigation {
    position: relative;
    .profile-pic-wrapper {
      width: 209px;
      height: 209px;
      position: absolute;
      top: -5rem;
      overflow: hidden;
      border: 10px solid $primary;
      border-radius: 50%;
      object-fit: cover;

      @media only screen and (max-width: 64em) {
        width: 100px;
        height: 100px;
        top: 2rem;
        border: 4px solid $primary;
      }

      ::v-deep(.p-image > img) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(0.9);
      }
    }

    .back-btn {
      position: absolute;
      z-index: 5;
      height: 3.5rem;
      width: 3.5rem;
      top: -5rem;
      @media only screen and (max-width: 64em) {
        top: 2rem;
        height: 2rem;
        width: 2rem;
      }
    }
    .profile-links {
      position: absolute;
      bottom: 1.5rem;
      right: 0;
      @media only screen and (max-width: 64em) {
        left: 0;
        bottom: 1rem;
      }
      a {
        padding: 1rem 0 0 0;
        text-decoration: none;
        color: black;
        @media only screen and (max-width: 64em) {
          padding: 0.5rem 0 0 0;
        }
        &.router-link-exact-active {
          border-top: 4px solid $primary;
          font-weight: bold;
        }
      }
    }
  }
  .profile-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actor-short-desc {
      margin-left: 15rem;
      @media only screen and (max-width: 64em) {
        margin-left: 8rem;
      }
    }
  }
}
</style>
