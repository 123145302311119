<template>
  <div class="data-div-container mb-5 mt-2">
    <Button
      type="button"
      icon="pi pi-filter-slash"
      label="Wyczyść"
      class="p-button-outlined mb-2"
      @click="clearFilters()"
    />
    <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.5em" />
    <div style="height: 71vh" class="mb-4">
      <DataTable
        :value="actors"
        :rows="params.limit"
        :paginator="true"
        currentPageReportTemplate="{currentPage}"
        :paginatorTemplate="{
          '640px':
            'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
          default:
            'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown',
        }"
        class="data-table"
        v-model:filters="params.filters"
        scrollHeight="flex"
        :scrollable="true"
        filterDisplay="menu"
        v-model:selection="selectedActors"
        dataKey="_id"
        ref="actorsTable"
        :first="params.first"
        :totalRecords="allActorsCount"
        :lazy="true"
        @page="onPage($event)"
        :pageLinkSize="12"
      >
        <Button
          v-if="!loading"
          class="select-all action-btn"
          @click="selectAllActors"
          >{{ canUnselectActors ? "Odznacz" : "Zaznacz" }}</Button
        >
        <template #empty> Nie znaleziono aktorów. </template>
        <Column selectionMode="multiple" frozen> </Column>
        <Column>
          <template #body="{ data }">
            <Image
              :src="getProfilePic(data.profilePic, data.photos)"
              imageClass="actor-avatar"
              preview
              alt="photo"
            />
          </template>
        </Column>
        <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :filterMatchModeOptions="col.matchModeOn ? matchModeOptions : null"
          :header="col.header"
          :class="col.class"
          :showFilterMatchModes="col.matchModeOn"
          :filterField="col.filterField ? col.field : ''"
          :filterMenuStyle="{ width: col.range ? '18rem' : 'auto' }"
        >
          <template #body="{ data }">
            <div v-if="linkFields.includes(col.field)">
              <a
                :href="data[col.field]"
                target="_blank"
                v-if="data[col.field]"
                class="video-link"
              >
                <Button type="button" class="p-button-secondary">
                  <i class="pi pi-video"></i>
                </Button>
              </a>
              <div v-else>Brak</div>
            </div>
            <div v-else-if="col.field === allowedStringFilters.video_links">
              <Button
                type="button"
                class="p-button-secondary"
                @click="navigateToVideos(data._id)"
              >
                <i class="pi pi-video"></i>
              </Button>
            </div>
            <div v-else-if="col.multiple">
              <span v-for="(type, i) in data[col.field]" :key="i"
                >{{ type }}{{ i + 1 !== data[col.field].length ? ", " : "" }}
              </span>
            </div>
            <div v-else-if="col.field === allowedStringFilters.birthday">
              {{ data.birthday ? formattedDate(data.birthday) : "" }}
            </div>
            <span
              v-else
              :class="!elipsedFields.includes(col.field) ? 'shrinked' : ''"
            >
              {{ data[col.field] }}
            </span>
          </template>
          <template
            #filter="{ filterModel }"
            v-if="!unfilteredColumns.includes(col.field)"
          >
            <div v-if="col.matchModeOn">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                :placeholder="col.placeholder"
              />
            </div>
            <div v-if="col.filterField">
              <MultiSelect
                v-model="filterModel.value"
                :options="col.options"
                :placeholder="col.placeholder"
                class="p-column-filter"
              />
            </div>
            <div v-if="col.range">
              <Slider
                v-show="false"
                v-model="filterModel.value"
                :range="true"
                class="mt-3"
                :min="+col.range.min"
                :max="+col.range.max"
              ></Slider>
              <div class="slider-container flex align-items-center mt-3">
                <div class="flex align-items-center w-5">
                  <span class="mr-3">Od:</span>
                  <input
                    type="number"
                    class="slider-filter"
                    :value="
                      filterModel.value && filterModel.value.length
                        ? filterModel.value[0]
                        : +col.range.min
                    "
                    @input="
                      (event) => {
                        filterModel.value
                          ? (filterModel.value[0] = event.target.value)
                          : (filterModel.value = [
                              event.target.value,
                              +col.range.max,
                            ]);
                      }
                    "
                  />
                </div>
                <div class="flex align-items-center w-5">
                  <span class="mr-3">Do:</span>
                  <input
                    type="number"
                    class="slider-filter"
                    :value="
                      filterModel.value && filterModel.value.length
                        ? filterModel.value[1]
                        : +col.range.max
                    "
                    @input="
                      (event) => {
                        filterModel.value
                          ? (filterModel.value[1] = event.target.value)
                          : (filterModel.value = [
                              +col.range.min,
                              event.target.value,
                            ]);
                      }
                    "
                  />
                </div>
              </div>
            </div>
          </template>

          <template #filterclear="{ filterCallback }">
            <Button
              type="button"
              label="Wyczyść"
              class="action-btn white-btn"
              @click="onClearProperty(filterCallback, col.field)"
            ></Button>
          </template>
          <template #filterapply="{ filterCallback }">
            <Button
              type="button"
              label="Zastosuj"
              @click="onFilterProperty(filterCallback)"
              class="action-btn orange-btn ml-2"
            ></Button>
          </template>
        </Column>

        <Column frozen alignFrozen="right" class="see-profile-col">
          <template #body="slotProps">
            <Button
              type="button"
              class="mr-3 action-btn orange-btn hidden lg:block"
              @click="seeProfile(slotProps.data._id)"
              >Zobacz profil</Button
            >
            <Button
              icon="pi pi-user"
              @click="seeProfile(slotProps.data._id)"
              class="p-button-rounded flex align-items-center orange-btn lg:hidden"
            />
          </template>
        </Column>
        <template #paginatorstart>
          <ConfirmPopup></ConfirmPopup>
          <Button
            type="button"
            :disabled="!selectedActors"
            @click="deleteActors($event)"
            class="mr-3 trash-btn under-table-btn"
            icon="pi pi-trash"
          />
          <Button
            type="button"
            @click="nameOfferAndShareActors()"
            class="share-btn under-table-btn"
            icon="pi pi-send"
          />
        </template>
      </DataTable>
    </div>
    <div class="flex action-buttons">
      <Button type="button" @click="addProfile" class="mr-3 action-btn"
        >Dodaj profil</Button
      >

      <CSVButton
        :getFilteredActors="getFilteredActors"
        class="mr-3"
        type="button"
        >Importuj z CSV</CSVButton
      >
      <Button
        type="button"
        @click="pickUpOfferAndShareActors()"
        class="mr-3 action-btn white"
        >Przeglądaj oferty</Button
      >
    </div>

    <Dialog v-model:visible="display">
      <template #header>
        <h3 class="m-0 pr-2">Oferta wygenerowana</h3>
      </template>

      <p>
        Kod dostępu: <span class="font-bold"> {{ accessCode }}</span>
      </p>

      <template #footer>
        <Button
          label="Ok"
          icon="pi pi-check"
          autofocus
          @click="() => (display = false)"
        />
      </template>
    </Dialog>
    <CreateOfferModal
      :isOfferModalOpen="isCreateOfferModalOpen"
      :selectedActors="selectedActors"
      @offerModalClose="toggleCreateOfferModal()"
      @offerModalCreateOffer="shareActors"
    ></CreateOfferModal>
    <EditOfferModal
      :isOfferModalOpen="isEditOfferModalOpen"
      :selectedActors="selectedActors"
      @offerModalClose="toggleEditOfferModal()"
    ></EditOfferModal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CSVButton from "../components/CSVButton.vue";
import ActorService from "../services/ActorService";
import OfferService from "../services/OfferService";
import CreateOfferModal from "./CreateOfferModal.vue";
import EditOfferModal from "./EditOfferModal.vue";

import {
  artist_category_enum,
  gender_enum,
  clothing_size_enum,
  hair_length_enum,
  hair_color_enum,
  eye_color_enum,
  skin_color_enum,
  yes_no_enum,
  driving_licence_enum,
  province_enum,
} from "../const/enums";
import allowedStringFilters from "../const/allowedStringFilters.js";
import allowedEnumFilters from "../const/allowedEnumFilters.js";
import allowedRangeFilters from "../const/allowedRangeFilters.js";
import { FilterMatchMode } from "primevue/api";
import { toastMixin } from "../mixins/toastMixin.js";
import { format } from "date-fns";
import defaultAvatar from "../assets/profile-pic.svg";

export default {
  name: "MainPage",
  components: {
    CSVButton,
    CreateOfferModal,
    EditOfferModal,
  },
  mixins: [toastMixin],
  data() {
    return {
      isCreateOfferModalOpen: false,
      isEditOfferModalOpen: false,
      defaultAvatar,
      calledOnPage: false,
      display: false,
      accessCode: "",
      artist_category_enum,
      gender_enum,
      clothing_size_enum,
      hair_length_enum,
      hair_color_enum,
      eye_color_enum,
      skin_color_enum,
      yes_no_enum,
      skills_enum: [],
      physical_characteristics_enum: [],
      allowedStringFilters,
      allowedEnumFilters,
      allowedRangeFilters,
      columns: [],
      matchModeOptions: [
        { label: "Zawiera", value: FilterMatchMode.CONTAINS },
        { label: "Nie zawiera", value: FilterMatchMode.NOT_CONTAINS },
      ],
      elipsedFields: [
        allowedStringFilters.theatrical_experience,
        allowedStringFilters.camera_experience,
        allowedStringFilters.remarks,
        allowedStringFilters.place_of_residence,
        allowedStringFilters.place_of_registered_residence,
      ],
      selectedActors: [],
      linkFields: [
        allowedStringFilters.additional_field,
        allowedStringFilters.archival,
        allowedStringFilters.second_additional_field,
        allowedStringFilters.business_card_link,
        allowedStringFilters.polish_movie,
      ],
      unfilteredColumns: [
        allowedStringFilters.business_card_link,
        allowedStringFilters.video_links,
        allowedStringFilters.additional_field,
        allowedStringFilters.archival,
        allowedStringFilters.second_additional_field,
        allowedStringFilters.enrollment_date,
        allowedStringFilters.birthday,
        allowedStringFilters.polish_movie,
      ],
      actors: [],
      loading: false,
      filters: null,
      allActorsCount: 0,
    };
  },
  computed: {
    ...mapGetters("actors", ["params", "storeSelectedActors"]),
    canUnselectActors() {
      const selectedActorsIds = this.selectedActors.map((actor) => actor._id);
      return this.actors.find((actor) => selectedActorsIds.includes(actor._id));
    },
  },
  watch: {
    selectedActors: {
      handler: async function () {
        await this.setSelectedActors(this.selectedActors);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("actors", [
      "setSelectedActors",
      "setParams",
      "clearParams",
      "loadActor",
    ]),
    toggleEditOfferModal() {
      if (this.selectedActors.length === 0) {
        this.$toast.add({
          severity: "error",
          summary: "Musisz najpierw wybrać aktorów!",
          detail: this.error,
          life: 5000,
        });
        return;
      }
      this.isEditOfferModalOpen = !this.isEditOfferModalOpen;
    },
    toggleCreateOfferModal() {
      if (this.selectedActors.length === 0) {
        this.$toast.add({
          severity: "error",
          summary: "Musisz najpierw wybrać aktorów!",
          detail: this.error,
          life: 5000,
        });
        return;
      }
      this.isCreateOfferModalOpen = !this.isCreateOfferModalOpen;
    },
    selectAllActors() {
      if (this.canUnselectActors) {
        this.selectedActors = this.selectedActors.filter((selectedActor) => {
          const matchActor = this.actors.find(
            (actor) => actor?._id === selectedActor?._id
          );
          return matchActor?._id !== selectedActor?._id;
        });
      } else {
        this.selectedActors = [...this.selectedActors, ...this.actors];
      }
    },
    pickUpOfferAndShareActors() {
      this.toggleEditOfferModal();
    },
    nameOfferAndShareActors() {
      this.toggleCreateOfferModal();
    },
    async shareActors(offerName) {
      try {
        if (!this.selectedActors.length)
          return this.displayToast(
            "info",
            "Wybierz przynajmniej jednego aktora w celu utworzenia oferty."
          );
        const actors = this.storeSelectedActors.map((actor) => {
          return { actorId: actor._id, selectedImages: actor.selectedPhotos };
        });
        const accessCode = await OfferService.generateOffer(actors, offerName);
        if (accessCode) {
          this.display = true;
          this.accessCode = accessCode;
        }
      } catch (err) {
        this.displayToast("error", "Wystąpił błąd podczas generowania oferty");
      }
    },
    async getEnumValues(attribute) {
      const attributes = await ActorService.getDistinctAttributes(attribute);
      const attributesWithNoEmptyValues = attributes.filter(
        (attribute) => attribute
      );
      return attributesWithNoEmptyValues;
    },
    async getFilteredActors() {
      this.loading = true;
      try {
        await ActorService.getFilteredActors(this.params).then((actorObj) => {
          this.actors = actorObj.actors;
          this.allActorsCount = actorObj.count;
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    async onAcceptDeleteActors() {
      try {
        if (!this.selectedActors.length) {
          return this.displayToast(
            "info",
            "Wybierz przynajmniej jednego aktora"
          );
        }
        const actorsIds = this.selectedActors.map((actor) => actor._id);
        await ActorService.deleteActors(actorsIds);
        await this.getFilteredActors();
        this.displayToast("success", "Aktorzy zostali usunięci pomyślnie!");
      } catch (err) {
        this.displayToast("error", "Wystąpił błąd podczas usuwania aktorów");
      }
    },
    async deleteActors(ev) {
      this.$confirm.require({
        target: ev.currentTarget,
        message: "Czy na pewno chcesz usunąć zaznaczonych aktorów?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Tak",
        rejectLabel: "Nie",
        accept: async () => {
          await this.onAcceptDeleteActors();
        },
      });
    },
    async seeProfile(id) {
      try {
        this.$router.push({
          name: "ProfileIndex",
          params: { id },
        });
      } catch (e) {
        this.displayToast(
          "error",
          "Wystąpił bład podczas pobierania danych aktora."
        );
      }
    },
    async onPage(event) {
      this.calledOnPage = true;
      this.params.first = event.first;
      this.params.page = event.page + 1;
      await this.setParams(this.params);
      await this.getFilteredActors();
      this.calledOnPage = false;
    },

    async onFilter() {
      this.params.stringQuery = [];
      for (let key in allowedStringFilters) {
        const matchModeOn = this.columns.find(
          (col) => col.field === key
        )?.matchModeOn;
        if (
          allowedStringFilters[key] &&
          matchModeOn &&
          this.params.filters[allowedStringFilters[key]].constraints[0].value
        ) {
          this.params.stringQuery.push({
            name: allowedStringFilters[key],
            val: this.params.filters[allowedStringFilters[key]].constraints[0]
              .value,
            contain:
              this.params.filters[allowedStringFilters[key]].constraints[0]
                .matchMode === FilterMatchMode.CONTAINS,
          });
        }
      }

      this.params.enumQuery = [];
      for (let key in allowedEnumFilters) {
        if (
          allowedEnumFilters[key] &&
          this.params.filters[allowedEnumFilters[key]].value
        ) {
          this.params.enumQuery.push({
            name: allowedEnumFilters[key],
            val: this.params.filters[allowedEnumFilters[key]].value.join(","),
          });
        }
      }

      this.params.rangeQuery = [];
      for (let key in allowedRangeFilters) {
        if (
          allowedRangeFilters[key] &&
          this.params.filters[allowedRangeFilters[key]].value
        ) {
          this.params.rangeQuery.push({
            name: allowedRangeFilters[key],
            gte: +this.params.filters[allowedRangeFilters[key]].value[0],
            lte: +this.params.filters[allowedRangeFilters[key]].value[1],
          });
        }
      }
      this.params.page = 1;
      await this.setParams(this.params);
      await this.getFilteredActors();
    },
    async onFilterProperty(callback) {
      callback();
      await this.onFilter();
    },
    async onClearProperty(callback, prop) {
      callback();
      if (allowedStringFilters[prop]) {
        this.params.filters[prop].constraints[0].value = null;
        this.params.stringQuery = this.params.stringQuery.filter(
          (item) => item.name !== prop
        );
        await this.setParams(this.params);
      }
      if (allowedEnumFilters[prop]) {
        this.params.filters[prop].value = null;
        this.params.enumQuery = this.params.enumQuery.filter(
          (item) => item.name !== prop
        );
        await this.setParams(this.params);
      }
      if (allowedRangeFilters[prop]) {
        this.params.filters[prop].value = null;
        this.params.rangeQuery = this.params.rangeQuery.filter(
          (item) => item.name !== prop
        );
        await this.setParams(this.params);
      }
      await this.getFilteredActors();
    },
    clearStringFilters() {
      for (let key in allowedStringFilters) {
        const matchModeOn = this.columns.find(
          (col) => col.field === key
        )?.matchModeOn;
        if (
          allowedStringFilters[key] &&
          matchModeOn &&
          this.params.filters[allowedStringFilters[key]].constraints[0].value
        ) {
          this.params.filters[allowedStringFilters[key]].constraints[0].value =
            null;
        }
      }
    },
    async clearFilters() {
      this.clearStringFilters();
      await this.clearParams();
      await this.getFilteredActors();
      this.selectedActors = [];
    },
    async navigateToVideos(id) {
      try {
        await this.loadActor(id);
        this.$router.push({ name: "Videos", params: { id } });
      } catch (err) {
        this.displayToast(
          "error",
          "Przekierowanie do filmów aktora nie powiodło się."
        );
      }
    },
    formattedDate(dateString) {
      const date = new Date(dateString);
      return format(date, "dd.MM.yyy");
    },
    addProfile() {
      this.$router.push({ name: "AddProfile" });
    },
    getProfilePic(profilePic, photos) {
      if (!profilePic || !photos) {
        return this.defaultAvatar;
      }

      return (
        profilePic.url || photos[0]?.url || photos[1]?.url || this.defaultAvatar
      );
    },
  },
  async created() {
    if (this.storeSelectedActors.length) {
      this.selectedActors = [...this.storeSelectedActors];
    }
    await this.getFilteredActors();
    this.columns = [
      {
        field: allowedStringFilters.identifierNumber,
        header: "ID",
        matchModeOn: true,
        placeholder: "Szukaj po ID",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "short-text-field id",
      },
      {
        field: allowedStringFilters.first_name,
        header: "Imię",
        matchModeOn: true,
        placeholder: "Szukaj po imieniu",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "short-text-field",
      },
      {
        field: allowedStringFilters.last_name,
        header: "Nazwisko",
        matchModeOn: true,
        placeholder: "Szukaj po nazwisku",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedStringFilters.city_of_residence,
        header: "Miejscowość zamieszkania",
        matchModeOn: true,
        placeholder: "Szukaj po miejscowości zamieszkania",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "large-text-field",
      },
      {
        field: allowedStringFilters.phone_number,
        header: "Numer kontaktowy",
        matchModeOn: true,
        placeholder: "Szukaj po numerze kontaktowym",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "large-text-field",
      },
      {
        field: allowedStringFilters.mail,
        header: "Adres E-mail",
        matchModeOn: true,
        placeholder: "Szukaj po adres e-mail",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "xlarge-text-field",
      },
      {
        field: allowedStringFilters.business_card_link,
        header: "Wizytówka",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "short-text-field",
      },
      {
        field: allowedStringFilters.polish_movie,
        header: "Filmpolski.pl",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "short-text-field",
      },
      {
        field: allowedStringFilters.video_links,
        header: "Filmy",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "short-text-field",
      },
      {
        field: allowedStringFilters.remarks,
        header: "UWAGI",
        matchModeOn: true,
        placeholder: "Szukaj w uwagach",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "xlarge-text-field",
      },
      {
        field: allowedRangeFilters.age,
        header: "Wiek",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: { min: 0, max: 100 },
        class: "short-text-field",
      },
      {
        field: allowedStringFilters.birthday,
        header: "Data urodzenia",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.artist_type,
        header: "Kategoria artysty",
        matchModeOn: false,
        placeholder: "Typ artysty",
        filterField: true,
        multiple: true,
        options: artist_category_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedStringFilters.camera_experience,
        header: "Doświadczenie TV",
        matchModeOn: true,
        placeholder: "Szukaj po doświadczeniu TV",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "large-text-field",
      },
      {
        field: allowedStringFilters.theatrical_experience,
        header: "Doświadczenie teatralne",
        matchModeOn: true,
        placeholder: "Szukaj po doświadczeniu teatralnym",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "xlarge-text-field",
      },
      {
        field: allowedEnumFilters.skills,
        header: "Umiejętności",
        matchModeOn: false,
        placeholder: "Umiejętności",
        filterField: true,
        multiple: true,
        options: await this.getEnumValues("skills"),
        range: null,
        class: "xlarge-text-field",
      },
      {
        field: allowedEnumFilters.driving_licence,
        header: "Prawo jazdy",
        matchModeOn: false,
        placeholder: "Prawo jazdy",
        filterField: true,
        multiple: true,
        options: driving_licence_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.physical_characteristics,
        header: "Znaki szczególne",
        matchModeOn: false,
        placeholder: "Znaki szczególne",
        filterField: true,
        multiple: true,
        options: await this.getEnumValues("physical_characteristics"),
        range: null,
        class: "xlarge-text-field",
      },
      {
        field: allowedRangeFilters.height,
        header: "Wzrost",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: province_enum,
        range: { min: 10, max: 250 },
        class: "short-text-field",
      },
      {
        field: allowedRangeFilters.weight,
        header: "Waga",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: { min: 1, max: 200 },
        class: "short-text-field",
      },
      {
        field: allowedRangeFilters.chest,
        header: "Klatka",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: { min: 10, max: 200 },
        class: "short-text-field",
      },
      {
        field: allowedRangeFilters.waist,
        header: "Pas",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: { min: 10, max: 200 },
        class: "short-text-field",
      },
      {
        field: allowedRangeFilters.hips,
        header: "Biodra",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: { min: 10, max: 200 },
        class: "short-text-field",
      },
      {
        field: allowedRangeFilters.collar,
        header: "Kołnierz",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: { min: 10, max: 100 },
        class: "short-text-field",
      },
      {
        field: allowedRangeFilters.shoe_number,
        header: "Rozmiar buta",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: { min: 10, max: 50 },
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.clothing_size,
        header: "Rozmiar odzieży",
        matchModeOn: false,
        placeholder: "Rozmiar odzieży",
        filterField: true,
        multiple: false,
        options: clothing_size_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.hair_length,
        header: "Długość włosów",
        matchModeOn: false,
        placeholder: "Długość włosów",
        filterField: true,
        multiple: false,
        options: hair_length_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.hair_color,
        header: "Kolor włosów",
        matchModeOn: false,
        placeholder: "Kolor włosów",
        filterField: true,
        multiple: true,
        options: hair_color_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.eye_color,
        header: "Kolor oczu",
        matchModeOn: false,
        placeholder: "Kolor oczu",
        filterField: true,
        multiple: true,
        options: eye_color_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.skin_color,
        header: "Kolor skóry",
        matchModeOn: false,
        placeholder: "Kolor skóry",
        filterField: true,
        multiple: false,
        options: skin_color_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedStringFilters.place_of_residence,
        header: "Miejsce zamieszkania",
        matchModeOn: true,
        placeholder: "Szukaj po miejscu zamieszkania",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.province,
        header: "Województwo",
        matchModeOn: false,
        placeholder: "Województwo",
        filterField: true,
        multiple: false,
        options: province_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedStringFilters.place_of_registered_residence,
        header: "Miejsce zameldowania",
        matchModeOn: true,
        placeholder: "Szukaj po miejscu zameldowania",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "large-text-field",
      },
      {
        field: allowedStringFilters.city_of_registered_residence,
        header: "Miejscowość zameldowania",
        matchModeOn: true,
        placeholder: "Szukaj po miejscowości zameldowania",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "large-text-field",
      },
      {
        field: allowedStringFilters.place_of_birth,
        header: "Miejsce urodzenia",
        matchModeOn: true,
        placeholder: "Szukaj po miejscu urodzenia",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.gender,
        header: "Płeć",
        matchModeOn: false,
        placeholder: "Płeć",
        filterField: true,
        multiple: false,
        options: gender_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedStringFilters.friend_phone_number,
        header: "Numer do bliskiej osoby",
        matchModeOn: true,
        placeholder: "Szukaj po numerze",
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.underwear_performance,
        header: "Udział w bieliźnie",
        matchModeOn: false,
        placeholder: "Udział w bieliźnie",
        filterField: true,
        multiple: false,
        options: yes_no_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.negative_role,
        header: "Negatywne role",
        matchModeOn: false,
        placeholder: "Negatywne role",
        filterField: true,
        multiple: false,
        options: yes_no_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedEnumFilters.no_criminal_record,
        header: "Niekarany/a",
        matchModeOn: false,
        placeholder: "Niekarany/a",
        filterField: true,
        multiple: false,
        options: yes_no_enum,
        range: null,
        class: "medium-text-field",
      },
      {
        field: allowedStringFilters.enrollment_date,
        header: "Data zapisu",
        matchModeOn: false,
        placeholder: null,
        filterField: false,
        multiple: false,
        options: null,
        range: null,
        class: "medium-text-field",
      },
    ];
  },
};
</script>

<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main_page";
@import "../styles/main";
$shortFieldSm: 90px;
$shortFieldLg: 120px;
$mediumFieldSm: 120px;
$mediumFieldLg: 150px;
$largeFieldSm: 140px;
$largeFieldLg: 180px;
$xlargeFieldSm: 220px;
$xlargeFieldLg: 250px;
@mixin shortField {
  &.short-text-field {
    min-width: $shortFieldLg;
    @media only screen and (max-width: 64em) {
      min-width: $shortFieldSm;
    }
    &.id {
      min-width: 70px;
      @media only screen and (max-width: 64em) {
        min-width: 50px;
      }
    }
  }
}
@mixin mediumField {
  &.medium-text-field {
    min-width: $mediumFieldLg;
    @media only screen and (max-width: 64em) {
      min-width: $mediumFieldSm;
    }
  }
}
@mixin xlargeField {
  &.xlarge-text-field {
    min-width: $xlargeFieldLg;
    @media only screen and (max-width: 64em) {
      min-width: $xlargeFieldSm;
    }
  }
}
@mixin largeField {
  &.large-text-field {
    min-width: $largeFieldLg;
    @media only screen and (max-width: 64em) {
      min-width: $largeFieldSm;
    }
  }
}
.data-table {
  background-color: $table-bg;
}
::v-deep(.p-datatable .p-datatable-thead > tr > th) {
  &:first-child {
    .p-column-header-content {
      display: none;
    }
  }
}
::v-deep(.p-datatable .p-datatable-thead > tr > th.p-frozen-column) {
  z-index: 1;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td.p-frozen-column) {
  z-index: 0;
}
::v-deep(.p-multiselect) {
  width: 250px;
}

::v-deep(.p-datatable .p-datatable-thead > tr > th) {
  padding: 0.25rem 1rem;
  @include shortField;
  @include mediumField;
  @include largeField;
  @include xlargeField;
  @media only screen and (max-width: 64em) {
    padding: 0.25rem 0.5rem;
  }
}

::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
  padding: 0.25rem 1rem;
  @include shortField;
  @include mediumField;
  @include largeField;
  @include xlargeField;
  @media only screen and (max-width: 64em) {
    padding: 0.25rem 0.5rem;
    font-size: 70%;
  }
  span {
    line-height: 1.6em;
    height: 3.2em;
    overflow: hidden;
    &.shrinked {
      display: flex;
      align-items: center;
    }
    &:nth-child(n + 3) {
      display: none;
    }
  }
}

::v-deep(.p-checkbox .p-checkbox-box) {
  @media only screen and (max-width: 64em) {
    width: 15px;
    height: 15px;
  }
}
::v-deep(.p-checkbox-icon.pi.pi-check) {
  display: flex;
  align-items: center;
}

::v-deep(.p-button) {
  position: unset;
}
::v-deep(.p-button-icon) {
  display: flex;
  align-items: center;
}
.select-all {
  position: absolute;
  top: 10px;
  left: 16px;
  z-index: 10;
  @media only screen and (max-width: 64em) {
    top: 10px;
    font-size: 60%;
    padding: 0.25rem 0.5rem;
  }
}
::v-deep(.p-frozen-column) {
  &.see-profile-col {
    min-width: 200px;
    @media only screen and (max-width: 64em) {
      min-width: unset;
    }
  }
}
.orange-btn {
  background-color: $primary;
  border: $primary;
}
::v-deep(.p-image) {
  width: 85px;
  height: 85px !important;
  @media only screen and (max-width: 64em) {
    height: 35px !important;
    width: 35px;
  }
  .actor-avatar {
    width: 85px;
    height: 85px;
    object-fit: cover;
    @media only screen and (max-width: 64em) {
      height: 35px;
      width: 35px;
    }
  }
}

.slider-filter {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

::v-deep(.p-datatable-thead) {
  @media only screen and (max-width: 64em) {
    font-size: 60%;
  }
}

.action-buttons {
  button {
    @media only screen and (max-width: 64em) {
      font-size: 60% !important;
      padding: 0.1rem 0.5rem;
      max-height: 1rem;
    }
  }
}
</style>
