import config from "../../configuration.json";
import axios from "axios";
import QueryHandler from "../helpers/queryHandler";

axios.defaults.withCredentials = true;

const url = `${config.VUE_APP_API_URL}/actor`;

class ActorService {
  static async upload(file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("actors", file);
      axios
        .post(`${url}/upload`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static async uploadImages(id, files) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      for (let file of files) {
        formData.append("images", file);
      }
      axios
        .put(`${url}/${id}/images`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static async uploadAvatar(id, file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("avatar", file);
      axios
        .put(`${url}/${id}/avatar`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static async deleteAvatar(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${url}/${id}/avatar`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static async deleteImage(id, imagePath) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${url}/${id}/images`, {
          data: {
            imagePath,
          },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static async getAvatar(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url}/${id}/avatar`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getFilteredActors(params) {
    const queriesString = QueryHandler.buildQuery(`${url}/getAll`, params);
    return new Promise((resolve, reject) => {
      axios
        .get(queriesString)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  }

  static addActor(actor) {
    return new Promise((resolve, reject) => {
      const requestBody = { actor };
      axios
        .post(`${url}/`, requestBody)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static updateActor(actor) {
    const updatedActor = { ...actor };
    const actorId = updatedActor._id;
    delete updatedActor._id;
    return new Promise((resolve, reject) => {
      axios
        .put(`${url}/${actorId}`, updatedActor)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  }

  static getActorById(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  }
  static deleteActors(actors_ids) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${url}/deleteActors`, { actors_ids })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  }

  static getDistinctAttributes(attributeValue) {
    const params = new URLSearchParams([["attribute", attributeValue]]);
    return new Promise((resolve, reject) => {
      axios
        .get(`${url}/distinct-attributes`, { params })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data.error);
        });
    });
  }
}
export default ActorService;
