import ActorService from "../../services/ActorService";

export default {
  async loadActor({ commit }, id) {
    return await ActorService.getActorById(id)
      .then((res) => {
        commit("SET_ACTOR", res);
        commit("SET_AVATAR", res.profilePic);
      })
      .catch(() => {
        commit("SET_ACTOR", null);
      });
  },
  async setSelectedActors({ commit }, selectedActors) {
    return commit("SET_SELECTED_ACTORS", selectedActors);
  },
  async clearSelectedActors({ commit }) {
    return commit("CLEAR_SELECTED_ACTORS");
  },
  async setParams({ commit }, params) {
    return commit("SET_PARAMS", params);
  },
  async clearParams({ commit }) {
    return commit("CLEAR_PARAMS");
  },
  async loadAvatar({ commit }, id) {
    return await ActorService.getAvatar(id)
      .then((res) => {
        commit("SET_AVATAR", res);
      })
      .catch(() => {
        commit("SET_AVATAR", null);
      });
  },
  async updateActor({ commit }, actor) {
    return await ActorService.updateActor(actor).then((res) => {
      commit("SET_ACTOR", res);
    });
  },
  async uploadAvatar({ commit }, { id, file }) {
    return await ActorService.uploadAvatar(id, file).then((res) => {
      commit("SET_AVATAR", res);
    });
  },
  async deleteAvatar({ commit }, { id }) {
    return await ActorService.deleteAvatar(id).then(() => {
      commit("SET_AVATAR", "");
    });
  },
  async deleteImage({ commit }, { id, imagePath }) {
    return await ActorService.deleteImage(id, imagePath).then(() => {
      commit("SET_AVATAR", "");
    });
  },
};
