module.exports = Object.freeze({
  skills: "skills",
  artist_type: "artist_type",
  gender: "gender",
  clothing_size: "clothing_size",
  hair_length: "hair_length",
  hair_color: "hair_color",
  eye_color: "eye_color",
  skin_color: "skin_color",
  physical_characteristics: "physical_characteristics",
  driving_licence: "driving_licence",
  underwear_performance: "underwear_performance",
  negative_role: "negative_role",
  no_criminal_record: "no_criminal_record",
  province: "province",
});
