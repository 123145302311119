<template>
  <Card class="main-card mt-5 px-2 md:px-4">
    <template #content>
      <div class="line" v-if="!inOffer">
        <div class="flex justify-content-between align-content-center">
          <div>
            <h2 class="mt-1">Zdjęcia</h2>
          </div>
          <div class="flex">
            <div v-if="avatar">
              <ConfirmPopup></ConfirmPopup>
              <Button
                v-if="loggedIn"
                class="action-btn mr-2"
                @click="handleDeleteAvatar($event)"
                >Usuń zdjęcie profilowe</Button
              >
            </div>
            <div>
              <FileUpload
                mode="basic"
                name="avatar"
                accept="image/*"
                chooseLabel="Zmień zdjęcie profilowe"
                :customUpload="true"
                @uploader="openCroppingModal"
              />
            </div>
          </div>
        </div>
      </div>
      <Galleria
        :value="photos"
        v-model:activeIndex="activeIndex"
        :circular="true"
        :fullScreen="true"
        :showItemNavigators="true"
        galleriaClass="custom-gallery"
        :showThumbnails="false"
        v-model:visible="displayCustom"
      >
        <template #item="slotProps">
          <div class="img-zoom-container">
            <img :src="slotProps.item?.url" alt="zdjęcie" />
            <p class="gallery-date">
              {{ formatToLocaleDate(slotProps.item?.createdAt) }}
            </p>
          </div>
        </template>
        <template #thumbnail="slotProps">
          <img :src="slotProps.item?.url" alt="zdjęcie" />
          <p class="gallery-date">
            {{ formatToLocaleDate(slotProps.item?.createdAt) }}
          </p>
        </template>
      </Galleria>
      <p v-if="!photos">Brak zdjęć</p>
      <div v-else class="grid mt-2">
        <div
          v-for="(image, index) of filteredPhotos"
          class="col-12 sm:col-6 md:col-4 lg:col-2 img-container mr-2 mb-4"
          :key="index"
        >
          <img
            v-if="image.url"
            :src="image.url"
            alt="zdjęcie"
            class="gall-img"
            @click="imageClick(image)"
          />
          <ConfirmPopup></ConfirmPopup>
          <Button
            v-if="loggedIn"
            @click="handleDeleteImage($event, image.url)"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger p-button-text times-btn"
          />
        </div>
      </div>
      <cropping-modal
        :open="croppingModalOpen"
        :file="file"
        @croppedImage="uploadAvatar"
        @resetModal="resetModal"
      >
      </cropping-modal>
    </template>
  </Card>
</template>
<script>
import { toastMixin } from "../mixins/toastMixin.js";
import { mapActions, mapGetters } from "vuex";
import CroppingModal from "./CroppingModal.vue";
export default {
  name: "ProfilePhotos",
  props: ["photos", "inOffer"],
  components: { CroppingModal },
  mixins: [toastMixin],
  data() {
    return {
      activeIndex: 0,
      displayCustom: false,
      photosListOfUrls: [],
      croppingModalOpen: false,
      file: null,
      filteredPhotos: [],
    };
  },
  watch: {
    photos: {
      handler: function (newVal) {
        if (newVal?.length > 0) {
          this.filteredPhotos = newVal.filter((photo) => {
            return photo.url?.length > 0;
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("admins", ["loggedIn"]),
    ...mapGetters("actors", ["actor", "avatar"]),
  },
  methods: {
    ...mapActions("actors", { storeUploadAvatar: "uploadAvatar" }),
    ...mapActions("actors", { storeDeleteAvatar: "deleteAvatar" }),
    ...mapActions("actors", { storeDeleteImage: "deleteImage" }),
    ...mapActions("actors", { storeLoadActor: "loadActor" }),
    formatToLocaleDate(date) {
      return new Date(date).toLocaleDateString("pl-PL", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    imageClick(img) {
      this.activeIndex = this.photos.findIndex(
        (photo) => photo.url === img.url
      );
      this.displayCustom = true;
    },
    resetModal() {
      this.croppingModalOpen = false;
      this.file = null;
    },
    openCroppingModal(ev) {
      this.croppingModalOpen = true;
      this.file = ev.files[0];
    },
    async uploadAvatar(file) {
      try {
        await this.storeUploadAvatar({
          id: this.actor._id,
          file,
        });
        this.displayToast("success", "Zdjęcie profilowe zostało zmienione.");
        this.resetModal();
      } catch (err) {
        console.error(err);
        this.displayToast("error", "Przesyłanie zdjęcia nie powiodło się.");
      }
    },
    handleDeleteAvatar(ev) {
      this.$confirm.require({
        target: ev.currentTarget,
        message: "Czy na pewno chcesz usunąć zdjęcie profilowe?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Tak",
        rejectLabel: "Nie",
        accept: async () => {
          await this.deleteAvatar();
        },
      });
    },
    handleDeleteImage(ev, image) {
      this.$confirm.require({
        target: ev.currentTarget,
        message: "Czy na pewno chcesz usunąć zdjęcie?",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: "Tak",
        rejectLabel: "Nie",
        accept: async () => {
          await this.deleteImage(image);
        },
      });
    },
    async deleteAvatar() {
      try {
        await this.storeDeleteAvatar({
          id: this.actor._id,
        });
        this.displayToast("success", "Zdjęcie profilowe zostało usunięte.");
      } catch (err) {
        this.displayToast("error", "Usuwanie zdjęcia nie powiodło się.");
      }
    },
    async deleteImage(imagePath) {
      try {
        await this.storeDeleteImage({
          id: this.actor._id,
          imagePath,
        });
        await this.storeLoadActor(this.actor._id);
        this.displayToast("success", "Zdjęcie zostało usunięte.");
      } catch (err) {
        this.displayToast("error", "Usuwanie zdjęcia nie powiodło się.");
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../styles/_variables";
@import "../styles/main";
@import "../styles/main_page";

.gallery-date {
  color: $secondary;
  margin-bottom: 1rem;
  text-align: center;
}
.img-zoom-container {
  border-style: solid;
  border-color: $tertiary;
  border-width: 1rem;
  border-radius: 15px;
  height: 70vh;
  img {
    height: 100%;
  }
}
.img-container {
  transition: all 0.4s;
  cursor: pointer;
  padding: 0;
  height: 250px;
  border: 2px solid $secondary;
  position: relative;
  .gall-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .times-btn {
    top: 0.25rem;
    position: absolute;
    right: 0.25rem;
    box-shadow: none !important;
    height: 25px !important;
    width: 25px !important;
    &:hover {
      background-color: white !important;
    }
  }
  &:hover {
    transform: scale(1.05);
    border: 2px solid $primary;
    filter: grayscale(20%);
  }
}
</style>
