<template>
  <div v-if="open" class="modal">
    <div class="modal-header">
      <h5 class="text-center">Dostosuj zdjęcie</h5>
    </div>
    <div class="modal-body">
      <div class="cropper-container">
        <cropper ref="cropper" :src="image.src" class="cropper" />
      </div>
    </div>

    <div class="my-2 text-center">
      <Button
        class="p-button-rounded p-button-danger p-button-text times-btn"
        data-bs-dismiss="modal"
        @click="reset"
      >
        Anuluj
      </Button>
      <Button class="btn btn-primary" data-bs-dismiss="modal" @click="crop">
        Zapisz
      </Button>
    </div>
  </div>
</template>
<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "CroppingModal",
  components: {
    Cropper,
  },
  props: {
    open: {
      type: Boolean,
      required: true,
      default: false,
    },
    file: {
      type: File,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      cropper: null,
      image: {
        src: null,
        type: null,
        name: null,
      },
    };
  },
  watch: {
    file: {
      handler: function (file) {
        if (file) {
          this.loadImage();
        } else {
          this.reset();
        }
      },
      deep: true,
    },
  },
  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        this.$emit(
          "croppedImage",
          new File([blob], this.image.name + "_avatar", {
            type: this.image.type,
          })
        );
      }, this.image.type);
    },
    reset() {
      this.image = {
        src: null,
        type: null,
        name: null,
      };
      this.$emit("resetModal");
    },
    getMimeType(file, fallback = null) {
      const byteArray = new Uint8Array(file).subarray(0, 4);
      let header = "";
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case "89504e47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          return "image/jpeg";
        default:
          return fallback;
      }
    },
    loadImage() {
      if (this.file) {
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        const blob = URL.createObjectURL(this.file);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.image = {
            src: blob,
            type: this.getMimeType(e.target.result, this.file.type),
            name: this.file.name,
          };
        };
        reader.readAsArrayBuffer(this.file);
      }
    },
  },
  unmounted() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
};
</script>
<style lang="scss">
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
</style>
