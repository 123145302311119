export const toastMixin = {
  methods: {
    displayToast(type = "info", msg) {
      this.$toast.add({
        severity: type,
        summary: msg,
        life: 4000,
      });
    },
  },
};
