<template>
  <BaseModal
    v-if="isOfferModalOpen"
    @closeModal="onOfferModalClose()"
    @confirmModal="onOfferModalConfirm()"
    @nextModal="onOfferModalNext()"
    @previousModal="onOfferModalPrevious()"
    :step="step"
    :maxSteps="2"
  >
    <template #header>
      <div>
        <h4>{{ headerText }}</h4>
        <div class="search" v-if="isFirstStep">
          <InputText
            placeholder="Szukaj oferty..."
            type="text"
            v-model="searchValue"
          />
        </div>
      </div>
    </template>
    <template #main v-if="isFirstStep">
      <div class="offer-list">
        <div
          v-for="offer in offersFiltered"
          :key="offer._id"
          class="offer-item my-2 p-3"
          @click="setActiveItem(offer._id)"
          :class="{ active: offer._id === activeItemId }"
        >
          <strong>{{ offer.name || "Oferta bez nazwy" }}</strong> |
          {{ offer.accessCode }}
        </div>
      </div>
    </template>
    <template v-else #main>
      <actors-photos-picker :actors="selectedActors"></actors-photos-picker>
    </template>
    <template #cancel-button> Anuluj </template>
    <template #previous-button> Wstecz </template>
    <template #confirm-button> Dodaj do oferty </template>
    <template #next-button> Dalej </template>
  </BaseModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActorsPhotosPicker from "./ActorsPhotosPicker.vue";
export default {
  components: { ActorsPhotosPicker },
  props: {
    isOfferModalOpen: {
      type: Boolean,
      default: false,
    },
    selectedActors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["offer-modal-close"],
  data() {
    return {
      activeItemId: null,
      searchValue: "",
      offerName: "",
      step: 1,
    };
  },
  watch: {
    isOfferModalOpen: async function (newVal) {
      if (newVal) {
        await this.storeGetAllOffers();
      }
    },
  },
  computed: {
    ...mapGetters("admins", ["offers"]),
    ...mapGetters("actors", ["storeSelectedActors"]),
    isFirstStep() {
      return this.step === 1;
    },
    headerText() {
      return this.isFirstStep ? "Lista ofert" : "Lista aktorów";
    },
    offersFiltered() {
      if (!this.searchValue) {
        return this.offers;
      }
      return this.offers.filter((offer) => {
        return (
          offer.accessCode.toLowerCase().includes(this.searchValue) ||
          offer.name?.toLowerCase().includes(this.searchValue)
        );
      });
    },
  },
  methods: {
    ...mapActions("admins", { storeGetAllOffers: "getAllOffers" }),
    ...mapActions("admins", { storeAddActorsToOffer: "addActorsToOffer" }),
    resetModal() {
      this.step = 1;
      this.activeItemId = null;
      this.offerName = "";
    },
    onOfferModalNext() {
      this.step += 1;
    },
    onOfferModalPrevious() {
      this.step -= 1;
    },
    onOfferModalClose() {
      this.resetModal();
      this.activeItemId = null;
      this.$emit("offer-modal-close");
    },
    validateOfferName() {
      const regex = /^.{1,100}$/;
      return regex.test(this.offerName);
    },
    async onOfferModalConfirm() {
      try {
        await this.addActorsToOffer();
        this.$toast.add({
          severity: "success",
          summary: "Aktorzy zostali dodani do oferty!",
          life: 5000,
        });
        this.$emit("offer-modal-close");
      } catch (err) {
        this.$toast.add({
          severity: "error",
          summary: "Wystąpił problem z dodaniem aktorów do oferty!",
          detail: this.error,
          life: 5000,
        });
      } finally {
        this.resetModal();
      }
    },
    setActiveItem(offerId) {
      this.activeItemId = offerId;
    },
    async addActorsToOffer() {
      await this.storeAddActorsToOffer({
        offerId: this.activeItemId,
        actors: this.storeSelectedActors,
      });
    },
  },
  mounted() {
    this.resetModal();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/_variables";
@import "../styles/main_page";
@import "../styles/main";
.offer-list {
  .offer-item {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
    &.active {
      border-color: $primary;
      border-width: 3px;
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
</style>